import * as React from 'react';
import { CompanyListView } from "./CompanyListView";
import useCompanies from "./hooks/useCompanies";
import CompanySetPlanModal from "../../setPlan/application/CompanySetPlanModal";
import useCompanyPlan from "./hooks/useCompanyPlan";
import { ServiceKey } from '../../../shared/application/service-provider/ServiceKey';
import CompanyPlanRepository from '../domain/company-plan.repository';
import CompanyRepository from '../domain/company.repository';
import useServiceProvider from '@pavabits/service-provider/dist/use.service-provider';
import useCompanyContract from '../../contract/application/hooks/useCompanyContract'
import { Routes } from '../../../../routing/Routes';
import { Router } from '../../../../routing/Router';
export function CompanyListDataContainer() {
    const companyRepository = useServiceProvider<CompanyRepository>(ServiceKey.CompanyRepository);
    const companyPlanRepository = useServiceProvider<CompanyPlanRepository>(ServiceKey.CompanyPlanRepository);
    const { companies, companiesLoading, pager, setPage, search, setSearch, updateCompany } = useCompanies(companyRepository);
    const { companyPlans, companyChangePlan, setCompanyChangePlan, companyChangePlanLoading, setPlan } = useCompanyPlan(companyPlanRepository, updateCompany);

    const { finalizeCompanyContract } = useCompanyContract()
    return (
        <>
            <CompanyListView
                companies={companies}
                loading={companiesLoading}
                pager={pager}
                setPage={setPage}
                search={search}
                setSearch={setSearch}
                onClickChangePlan={(item) => setCompanyChangePlan(item)}
                onClickConfigContract={(item) => (Router.goToRoute(Routes.COMPANY_CONTRACT_CONFIG, { id: item.id }))}
                onClickFinalize = {(item) => finalizeCompanyContract(item.id)}
            />
            <CompanySetPlanModal
                loading={companyChangePlanLoading}
                visible={companyChangePlan !== undefined}
                onHide={() => setCompanyChangePlan(undefined)}
                company={companyChangePlan}
                companyPlans={companyPlans}
                setPlan={setPlan}
            />
        </>
    );
}
