import * as React from 'react';
import { AppConfig } from '../../../AppConfig';

export const PsrTopbarVersion: React.FunctionComponent = () => {

    const prod: boolean = process.env.REACT_APP_ENV === 'prod';

    if ( prod ) return null;

    return (
        <div className={ 'text-inline' }>
            v { AppConfig.VERSION }
        </div>
    );
};
