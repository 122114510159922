import * as React from 'react';
import classNames from 'classnames';
import { TestProps } from '../TestProps';
import { StyledProps } from '../StyledProps';

interface Props {
    value: string
    label: string
    labelFor?: string
    type?: 'text' | 'password' | 'email'
    required?: boolean
    autoFocus?: boolean
    placeholder?: string
    autoComplete?: string
    classNameLabel?: string
    classNameInput?: string
    onChange: (newValue: string) => void
    onEnterPress?: () => void
}

export class PsrTextInput extends React.Component<Props & StyledProps & TestProps> {

    public render(): React.ReactNode {
        const {
            value,
            label,
            labelFor,
            type = 'text',
            required,
            autoFocus,
            placeholder,
            autoComplete,
            style,
            className,
            classNameLabel,
            classNameInput,
            dataCy
        } = this.props;

        const classes = classNames('form-group', className);
        const classesLabel = classNames('font-bold', classNameLabel);
        const classesInput = classNames('form-control p-l-0', classNameInput);

        return (
            <div className={ classes } style={ style }>
                <label
                    htmlFor={ labelFor }
                    className={ classesLabel }
                    children={ label }
                />
                <input
                    type={ type }
                    required={ required }
                    autoFocus={ autoFocus }
                    value={ value }
                    onChange={ this.handleChange }
                    placeholder={ placeholder }
                    className={ classesInput }
                    onKeyPress={ this.handleEnterPress }
                    autoComplete={ autoComplete }
                    data-cy={ dataCy }
                />
            </div>
        );
    }

    private handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        this.props.onChange(event.target.value);
    };

    private handleEnterPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        const { onEnterPress } = this.props;
        if ( onEnterPress && event.key === 'Enter' ) onEnterPress();
    };

}

