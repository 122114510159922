export const TR_PAVASAL = 'TR_PAVASAL';
export const TR_ERROR_INESPERADO = 'TR_ERROR_INESPERADO';
export const TR_FUNCIONALIDAD_NO_DISPONIBLE = 'TR_FUNCIONALIDAD_NO_DISPONIBLE';
export const TR_CAMPO_OBLIGATORIO = 'TR_CAMPO_OBLIGATORIO';
export const TR_USUARIO = 'TR_USUARIO';
export const TR_CONTRASENA = 'TR_CONTRASENA';
export const TR_INTRODUCE_EMAIL = 'TR_INTRODUCE_EMAIL';
export const TR_ACCEDER = 'TR_ACCEDER';
export const TR_SOPORTE_PARA_EL_SISTEMA_DE_GESTION_DE_FACTURAS = 'TR_SOPORTE_PARA_EL_SISTEMA_DE_GESTION_DE_FACTURAS';
export const TR_INICIO_DE_SESION = 'TR_INICIO_DE_SESION';
export const TR_INTRODUCE_USUARIO_Y_CONTRASENA_PARA_ACCEDER_A_LA_PLATAFORMA_DE_SOPORTE = 'TR_INTRODUCE_USUARIO_Y_CONTRASENA_PARA_ACCEDER_A_LA_PLATAFORMA_DE_SOPORTE';
export const TR_RECUERDAME = 'TR_RECUERDAME';
export const TR_FALTAN_DATOS = 'TR_FALTAN_DATOS';
export const TR_EL_ELEMENTO_NO_EXISTE = 'TR_EL_ELEMENTO_NO_EXISTE';
export const TR_NO_TIENES_PERMISOS_PARA_REALIZAR_ESTA_ACCION = 'TR_NO_TIENES_PERMISOS_PARA_REALIZAR_ESTA_ACCION';
export const TR_OPERACION_NO_PERMITIDA = 'TR_OPERACION_NO_PERMITIDA';
export const TR_SE_HA_PRODUCIDO_UN_ERROR_OBTENIENDO_INFORMACION = 'TR_SE_HA_PRODUCIDO_UN_ERROR_OBTENIENDO_INFORMACION';
export const TR_LA_CUENTA_NO_HA_SIDO_VALIDADA = 'TR_LA_CUENTA_NO_HA_SIDO_VALIDADA';
export const TR_CONTRASENA_INCORRECTA = 'TR_CONTRASENA_INCORRECTA';
export const TR_EL_USUARIO_NO_EXISTE = 'TR_EL_USUARIO_NO_EXISTE';
export const TR_CERRAR_SESION = 'TR_CERRAR_SESION';
export const TR_EMPRESAS = 'TR_EMPRESAS';
export const TR_CANCELAR = 'TR_CANCELAR';
export const TR_ACEPTAR = 'TR_ACEPTAR';
export const TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESION = 'TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESION';
export const TR_IR_A_LA_PAGINA = 'TR_IR_A_LA_PAGINA';
export const TR_ELEMENTOS = 'TR_ELEMENTOS';
export const TR_BUSCAR = 'TR_BUSCAR';
export const TR_NIF = 'TR_NIF';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_PLAN = 'TR_PLAN';
export const TR_CAMBIAR_PLAN = 'TR_CAMBIAR_PLAN';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_CIUDAD = 'TR_CIUDAD';
export const TR_PROVINCIA = 'TR_PROVINCIA';
export const TR_SELECCIONA_UN_PLAN_DE_EMPRESA = 'TR_SELECCIONA_UN_PLAN_DE_EMPRESA';
export const TR_PLAN_DE_EMPRESA_ACTUALIZADO_CORRECTAMENTE = 'TR_PLAN_DE_EMPRESA_ACTUALIZADO_CORRECTAMENTE';
export const TR_CONFIGURACION_DEL_PLAN_DE_LA_EMPRESA = 'TR_CONFIGURACION_DEL_PLAN_DE_LA_EMPRESA';
export const TR_CONTRATO = 'TR_CONTRATO';
export const TR_USUARIOS = 'TR_USUARIOS';
export const TR_EXPORTAR_USUARIOS_COMUNICACIONES_COMERCIALES = 'TR_EXPORTAR_USUARIOS_COMUNICACIONES_COMERCIALES';
