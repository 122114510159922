import * as React from 'react';
import { PsrTopbarCollapser } from './PsrTopbarCollapser';
import { PsrTopbarBrand } from './PsrTopbarBrand';
import { PsrTopbarVersion } from './PsrTopbarVersion';
import { PsrTopbarUser } from './PsrTopbarUser';
import { User } from '../User';

interface Props {
    user: User
    onClickCollapse: () => void
    onClickBrand: () => void
    onLogout: () => void
    children?: never
}

export const PsrTopbar: React.FunctionComponent<Props> = (
    {
        user,
        onClickCollapse,
        onClickBrand,
        onLogout,
    }
) => {
    return (
        <nav className="navbar navbar-expand-md">
            <div className="left-navbar">
                <PsrTopbarCollapser onClickCollapse={ onClickCollapse }/>
                <PsrTopbarBrand onClickBrand={ onClickBrand }/>
                <PsrTopbarVersion/>
            </div>
            <div className="right-navbar">
                <PsrTopbarUser
                    userName={ user.name }
                    userImage={ user.image }
                    onLogout={ onLogout }
                />
            </div>
        </nav>
    );
};
