import cookie from 'react-cookies';
import { LoginData } from '../domain/login-data';
import { AuthData } from '../domain/auth-data';

export class AuthManager {

    private static COOKIE_KEY = 'PROVIDER_SUPPORT_MASTER_DATA';
    private static INSTANCE = new AuthManager();

    private masterData?: AuthData;

    private constructor(
    ) {}

    public static getInstance(): AuthManager {
        return AuthManager.INSTANCE;
    }

    public initialize() {
        const masterData: AuthData = cookie.load(AuthManager.COOKIE_KEY);
        if (masterData) {
            this.masterData = masterData;
        } else {
            this.masterData = undefined;
        }
    };

    public login(data: LoginData) {
        this.masterData = data.getData();
        cookie.save(AuthManager.COOKIE_KEY, this.masterData);
    }

    public logout() {
        this.masterData = undefined;
        cookie.remove(AuthManager.COOKIE_KEY);
    }

    public getAuthToken(): string | undefined {
        return this.masterData?.token;
    };

    public isLogged(): boolean {
        return !!this.getAuthToken();
    };


}

export const AuthManagerInstance = AuthManager.getInstance();
