import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory, History } from 'history';
import { Routing } from './routing/Routing';
import { AuthManagerInstance } from './modules/auth/application/auth-manager';
import { CombinedReducers } from './redux/CombineReducers';
import { ServiceKey } from './modules/shared/application/service-provider/ServiceKey';
import ApiCompanyRepository from './modules/company/list/infrastructure/api/api.company.repository';
import ApiCompanyPlanRepository from './modules/company/list/infrastructure/api/api.company-plan.repository';
import ServiceProvider from '@pavabits/service-provider/dist/service-provider';
import ApiFileRepository from './components/form-file/infrastructure/api/ApiFileRepository';
import { ReduxStorageUtils } from '@cuatroochenta/co-redux-builders';
import ApiUserRepository from './modules/user/infrastructure/ApiUserRepository';

ServiceProvider.instance.define(ServiceKey.CompanyRepository, ApiCompanyRepository)
ServiceProvider.instance.define(ServiceKey.CompanyPlanRepository, ApiCompanyPlanRepository)
ServiceProvider.instance.define(ServiceKey.FileRepository, ApiFileRepository)
ServiceProvider.instance.define(ServiceKey.UserRepository, ApiUserRepository)

function App() {
    const history: History = createBrowserHistory();
    const middleware = routerMiddleware(history);
    const store = createStore(CombinedReducers, applyMiddleware(thunk, middleware));
    ReduxStorageUtils.initialize(store);
    AuthManagerInstance.initialize();

    return (
        <Provider store={ ReduxStorageUtils.getStorage() }>
            <Routing history={ history }/>
        </Provider>
    );
}

export default App;
