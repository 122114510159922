import FileRepository from '../../domain/FileRepository';
import FileUploaded from '../../domain/FileUploaded';
import TaskFileUpload from '../ws/TaskFileUpload';

export default class ApiFileRepository implements FileRepository {
    uploadFile(file: File, companyId: string): Promise<FileUploaded> {
        return new TaskFileUpload(file, companyId)
            .executeAsPromise()
            .then((result) => {
                if (!result.success || !result.data || !result.data.upload_file) {
                    throw new Error('Error subiendo fichero')
                }
                return new FileUploaded(
                    result.data.upload_file.id,
                    result.data.upload_file.url,
                    result.data.upload_file.name,
                    result.data.upload_file.size,
                )
            })
    }
}
