import * as React from 'react';
import {PsrLoadingView} from "../../../../components/psrLoadingView/PsrLoadingView";
import {CompanyEntity} from "../../../shared/infrastructure/ws/ApiModel";
import PsrPager from "../../../../components/psrPager/PsrPager";
import {ApiPager} from "../../../shared/infrastructure/ws/ApiRequest";
import PsrSearcher from "../../../../components/psrSearcher/PsrSearcher";
import PsrTable from "../../../../components/psrTable/PsrTable";
import {CompanyTableItemView, CompanyTableItemViewHeaders} from "./CompanyTableItemView";

export function CompanyListView({companies, loading, pager, setPage, search, setSearch, onClickChangePlan, onClickConfigContract, onClickFinalize}: {
    companies: CompanyEntity[],
    loading: boolean,
    pager: ApiPager,
    setPage: (page: number) => void,
    search: string,
    setSearch: (search: string) => void,
    onClickChangePlan: (item: CompanyEntity) => void,
    onClickConfigContract: (item: CompanyEntity) => void,
    onClickFinalize:(item: CompanyEntity) => void
}) {
    return (
        <div className={'col p-l-15 p-r-15 m-t-20'}>
            <PsrSearcher value={search} onChange={setSearch} />
            <PsrTable
                headers={CompanyTableItemViewHeaders}
                data={companies}
                ItemTableView={CompanyTableItemView}
                onClickItem={onClickChangePlan}
                onClickMenu={onClickConfigContract}
                onClickFinalize={onClickFinalize}
            />
            <PsrPager data={pager} onPage={setPage} loading={loading} />
            <PsrLoadingView loading={loading} />
        </div>
    );
}
