import { AppConfig } from '../../../../AppConfig';

export class ApiEndpoints {
    public static SUPPORT_LOGIN = `${ AppConfig.BASE_ENDPOINT }/support/auth/login`; // POST
    public static SUPPORT_COMPANIES = `${ AppConfig.BASE_ENDPOINT }/support/companies`; // POST
    public static SUPPORT_SET_PLANT_TO_COMPANY = `${ AppConfig.BASE_ENDPOINT }/support/companies/:uuid/plan`; // POST
    public static USER_CURRENT_INFO = `${ AppConfig.BASE_ENDPOINT }/users/current/info`; // POST
    public static USER_EXPORT_ACCEPT_COMERCIAL_COMMUNICATIONS = `${ AppConfig.BASE_ENDPOINT }/support/user/accept-comercial-communications/export`; // POST
    public static USER_ROLE_COMPANY_CURRENT_LIST = `${ AppConfig.BASE_ENDPOINT }/userrolecompany/current`; // GET
    public static SUPPORT_COMPANY_CONTRACT_INFO = `${ AppConfig.BASE_ENDPOINT }/support/company/contract/:uuid`; // POST & GET & PUT
    public static SUPPORT_COMPANY_FINALIZE_CONTRACT = `${ AppConfig.BASE_ENDPOINT }/support/company/contract/finalize/:uuid`; // POST & GET & PUT
    public static UPLOAD = `${ AppConfig.BASE_ENDPOINT }/upload`; // POST
}
