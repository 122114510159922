import * as React from 'react';
import {ChangeEventHandler, Component, FocusEventHandler, ReactNode} from 'react';
import classNames from "classnames";

export interface PrsSelectOption {
    value: string,
    name: string,
}

export interface PrsSelectProps {
    label?: string,
    value: string,
    options: PrsSelectOption[],
    name?: string,
    error?: string,
    disabled?: boolean,
    onChange?: ChangeEventHandler<HTMLSelectElement>,
    onFocus?: FocusEventHandler<HTMLSelectElement>,
    onBlur?: FocusEventHandler<HTMLSelectElement>,
    classFormGroup?: string,
    classFormLine?: string,
    classSelect?: string,
    emptyOption?: boolean,
    labelClassName?: string,
    className?: string,
    allSelectedOption?: boolean,
    placeholder?: string,
    required?: boolean,
}

export default class PrsSelect extends Component<PrsSelectProps> {

    public render(): ReactNode {
        const {label} = this.props;

        const classFormGroup = classNames('form-group', this.props.classFormGroup);
        const classFormLine = classNames('form-line', this.props.classFormLine);
        const classSelectComponent = classNames('form-control', 'show-tick', this.props.classSelect);
        return (
            <div className={classFormGroup}>
                <div className={classFormLine}>
                    {label &&
                    <label className={this.props.labelClassName}>
                        {label} {this.props.required && <span className={"required-field"}>{` *`}</span>}
                    </label>}
                    <select
                        className={`${classSelectComponent} ${this.props.className}`}
                        name={this.props.name}
                        disabled={this.props.disabled}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                    >
                        {this.props.emptyOption && <option key={0} value={'-1'}/>}
                        {this.props.placeholder && <option key={-1} value={'-1'} disabled>
                            {this.props.placeholder}
                        </option>}
                        {this.props.options.map((option: PrsSelectOption) => <option key={option.value} value={option.value}>{option.name}</option>)}
                    </select>
                </div>
                {this.props.error ? <label className="error">{this.props.error || ''}</label> : null}
            </div>
        );
    }

}

