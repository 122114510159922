import * as React from 'react';
import { PsrLoadingView } from '../../../components/psrLoadingView/PsrLoadingView';
import { PsrButton } from '../../../components/psrButton/PsrButton';
import { I18nUtils } from '../../../i18n/I18nUtils';
import { TR_EXPORTAR_USUARIOS_COMUNICACIONES_COMERCIALES } from '../../../i18n/constants';

export function UsersView({ onExportAcceptComercialCommunications, loading }: {
    loading: boolean,
    onExportAcceptComercialCommunications:() => void
}) {
    return (
        <div className={'p-l-15 p-r-15 m-t-20 pull-right'}>
            <PsrButton
                text={I18nUtils.tr(TR_EXPORTAR_USUARIOS_COMUNICACIONES_COMERCIALES)}
                onClick={onExportAcceptComercialCommunications}
                className={'btn btn-header-primary'}
            />
            <PsrLoadingView loading={loading} />
        </div>
    );
}
