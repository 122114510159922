import * as React from 'react';
import { ReactNode } from 'react';
import { StyledProps } from '../StyledProps';

interface Props {
    text: string
    type?: 'submit' | 'reset' | 'button'
    onClick?: () => void
    disabled?: boolean
    children?: ReactNode
}

export const PsrButton: React.FunctionComponent<Props & StyledProps> = (
    {
        text,
        type,
        onClick,
        disabled,
        className,
        style,
        children,
    }
) => {
    return (
        <button
            style={ style }
            className={ className }
            onClick={ onClick }
            type={ type || 'button' }
            disabled={ disabled }
        >
            { text }
            { children }
        </button>
    );
};
