import * as React from 'react';
import { UsersDataContainer } from "./UsersDataContainer";
import { TR_USUARIOS } from '../../../i18n/constants';
import { I18nUtils } from '../../../i18n/I18nUtils';
import { PsrDashboard } from '../../../components/psrDashboard/PsrDashboard';
import { BreadcrumbManager } from '../../../components/psrDashboard/psrBreadcrumb/BreadcrumbManager';
import { Routes } from '../../../routing/Routes';

export function UsersScreen() {

    const breadcrumbManager = new BreadcrumbManager();
    breadcrumbManager.add({ name: I18nUtils.tr(TR_USUARIOS), url: Routes.USERS });

    return (
        <PsrDashboard breadcrumbManager={breadcrumbManager}>
            <UsersDataContainer/>
        </PsrDashboard>
    );
}
