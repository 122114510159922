import * as React from "react";
import { PsrTableItemProps } from "./PsrTableItemProps";

export interface PrsTableHeader {
    name: string
}

export default function PsrTable<T>({ headers, data, ItemTableView, onClickItem, onClickMenu, onClickFinalize }: {
    headers: PrsTableHeader[];
    data: T[];
    ItemTableView: React.ElementType<PsrTableItemProps<T>>;
    onClickItem: (item: T) => void;
    onClickMenu: (item: T) => void,
    onClickFinalize: (item: T) => void,
}) {

    return (
        <table
            className={"table-hover table-responsive-lg table fixed-head"}>
            <thead>
            <tr>
                {headers.map((header, index: number) => (<th key={index}>{header.name}</th>))}
            </tr>
            </thead>
            <tbody>
            {data.map((item: T, index: number) => (
                <ItemTableView
                    key={index}
                    item={item}
                    index={index}
                    onClickItem={onClickItem}
                    onClickMenu={onClickMenu}
                    onClickFinalize={onClickFinalize}
                />
            ))}
            </tbody>
        </table>
    );
}
