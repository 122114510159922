export default class FileUploaded {
    constructor(
       private readonly _id: string,
       private readonly _url: string,
       private readonly _name: string,
       private readonly _size: number,
    ) {}


    get url(): string {
        return this._url;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get size(): number {
        return this._size;
    }
}
