import * as React from 'react';
import {I18nUtils} from "../../../../i18n/I18nUtils";
import {TR_CAMBIAR_PLAN, TR_CANCELAR, TR_SELECCIONA_UN_PLAN_DE_EMPRESA} from '../../../../i18n/constants';
import ModalProps from "../../../../components/modal/ModalProps";
import {Modal} from "react-bootstrap";
import {PsrLoadingView} from '../../../../components/psrLoadingView/PsrLoadingView';
import {PsrRow} from '../../../../components/psrLayaout/PsrRow';
import {PsrButton} from "../../../../components/psrButton/PsrButton";
import {CompanyEntity} from "../../../shared/infrastructure/ws/ApiModel";
import PrsSelect, {PrsSelectOption} from "../../../../components/prsSelect/Select";
import {useEffect, useState} from "react";

interface CompanySetPlanModalProps extends ModalProps {
    loading: boolean,
    company?: CompanyEntity,
    companyPlans: PrsSelectOption[],
    setPlan: (companyPlanId: string, companyId: string) => void,
}

export default function CompanySetPlanModal({visible, onHide, company, loading, setPlan, companyPlans}: CompanySetPlanModalProps) {

    const [value, setValue] = useState<string>('');

    useEffect(()=> {
        if (company && company.company_plan) {
            setValue(company.company_plan.id)
        }
    }, [company])

    return (
        <Modal
            onHide={ onHide }
            show={ visible }
        >
            <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="signin-title">
                    { I18nUtils.tr(TR_CAMBIAR_PLAN) } { company ? company.name : '' }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={ '' }>
                <PrsSelect
                    label={I18nUtils.tr(TR_SELECCIONA_UN_PLAN_DE_EMPRESA)}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    options={companyPlans}
                />
            </Modal.Body>
            <Modal.Footer>
                <PsrRow className="form-footer-container">
                    <PsrButton
                        text={ I18nUtils.tr(TR_CANCELAR) }
                        onClick={ onHide }
                        type={ 'button' }
                        className="btn btn-lg btn-form float-right btn-cancel"
                        disabled={loading}
                    />
                    <PsrButton
                        text={ I18nUtils.tr(TR_CAMBIAR_PLAN) }
                        onClick={ () => setPlan(value, company!.id) }
                        type={ 'button' }
                        className="btn btn-lg btn-form float-right m-l-15 btn-submit"
                        disabled={loading}
                    />
                </PsrRow>
            </Modal.Footer>
            {loading && <PsrLoadingView loading={ true }/>}
        </Modal>
    );
};
