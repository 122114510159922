import * as React from 'react';
import { BreadcrumbManager } from './BreadcrumbManager';
import { PsrIcon } from '../../psrIcon/PsrIcon';

interface Props {
    breadcrumbManager: BreadcrumbManager
}

export const PsrBreadcrumb: React.FunctionComponent<Props> = (
    {
        breadcrumbManager,
    }
) => {
    if ( breadcrumbManager.isEmpty() ) return null;

    const breadcrumbs = breadcrumbManager.getAll();

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb bread-crumb">
                {
                    breadcrumbs.map((breadcrumb, index) => {
                        const {
                            id = index,
                            name,
                            url = '',
                            icon,
                        } = breadcrumb;

                        return (
                            <li key={ id }>
                                <a href={ url }>
                                    {
                                        icon ? (
                                            <PsrIcon
                                                icon={ icon }
                                                className={ index === 0 ? 'm-r-10' : 'm-l-10 m-r-10' }
                                            />
                                        ) : null
                                    }
                                    { name }
                                </a>
                                {
                                    index !== breadcrumbs.length - 1 ? <span className={ 'm-l-5' }/> : null
                                }
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
};
