const env = window as any;

export class AppConfig {
    public static VERSION = 0.1;
    public static BASE_ENDPOINT = env.API_HOST;
    public static PAGER = {
        elementsPage: 10,
        limits: [10, 25, 50]
    };
}
