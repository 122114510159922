import * as React from 'react';
import classNames from 'classnames';
import { StyledProps } from '../StyledProps';

interface Props {
    id?: string
    clearfix?: boolean
    onClick?: () => void
}

export const PsrRow: React.FunctionComponent<Props & StyledProps> = (
    {
        id,
        clearfix = false,
        className,
        style,
        onClick,
        children,
    }
) => {
    const classes = classNames('row', className, { clearfix: clearfix });

    return (
        <div
            id={ id }
            className={ classes }
            onClick={ onClick }
            style={ style }
            children={ children }
        />
    );
};

