import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import { StyledProps } from '../StyledProps';
import classNames from 'classnames';
import './PsrLoadingView.scss';

interface Props {
    size?: number
    loading: boolean
    children?: never
}

export const PsrLoadingView: React.FunctionComponent<Props & StyledProps> = (
    {
        size,
        loading = false,
        className,
        style,
    }
) => {
    const classes = classNames('psr-loading-view', className);

    if ( !loading ) return null;

    return (
        <div className={ classes } style={ style }>
            <div className={ 'align-middle' }>
                <ClipLoader
                    size={ size || 50 }
                    color={ '#FBB907' }
                    loading={ loading }
                />
            </div>
        </div>
    );
};

