import { AuthData } from './auth-data';

export class LoginData {

    private token: string;
    private rememberMe: boolean;

    constructor(token: string, rememberMe: boolean) {
        this.token = token;
        this.rememberMe = rememberMe;
    }

    public getData(): AuthData {
        return {
            token: this.token,
            expirationTime: this.rememberMe ? 60 * 60 * 8 : -1,
        };
    }

}
