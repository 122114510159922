import { METHOD } from '@cuatroochenta/co-generic-request';
import { ApiEndpoints } from '../../../shared/infrastructure/ws/ApiEndpoints';
import { ApiRequest, ApiResponse } from '../../../shared/infrastructure/ws/ApiRequest';
import { UserRoleCompanyEntity } from '../../../shared/infrastructure/ws/ApiModel';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TYPES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

type Request = undefined;
type Response = ApiResponse<UserRoleCompanyEntity[]>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TASKS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class TaskUserRoleCompanyCurrentList extends ApiRequest<Request, Response> {

    public constructor() {
        super(
            METHOD.GET,
            ApiEndpoints.USER_ROLE_COMPANY_CURRENT_LIST
        );
    }
}
