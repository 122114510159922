import * as React from 'react';

// @ts-ignore
export const PsrDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        className="psr-dropdown-toggle"
        // @ts-ignore
        ref={ ref }
        onClick={ (e) => {
            e.preventDefault();
            onClick(e);
        } }
    >
        { children }
    </a>
));
