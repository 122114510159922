import CompanyRepository from '../../domain/company.repository';
import { CompanyEntity } from '../../../../shared/infrastructure/ws/ApiModel';
import { ApiPager, ApiResponse } from '../../../../shared/infrastructure/ws/ApiRequest';
import { TaskSupportCompanies } from '../ws/TaskSupportCompanies';

export default class ApiCompanyRepository implements CompanyRepository {
    public async get(page: number, search: string): Promise<{ companies: CompanyEntity[]; pager: ApiPager }> {
        return new TaskSupportCompanies({
            limit: 10,
            page: page,
            sort: '+name',
            search: search,
        })
            .executeAsPromise()
            .then((response: ApiResponse<CompanyEntity[]>) => ({
                companies: response.data,
                pager: response.pager as ApiPager,
            }))
    }
}
