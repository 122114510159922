import { push } from 'react-router-redux';
import { ReduxStorageUtils } from '@cuatroochenta/co-redux-builders';

type Dic = { [key: string]: string }

export class Router {

    public static goToRoute(route: string, params: Dic = {}): void {
        const routeWithParams = Router.generateRoute(route, params);
        ReduxStorageUtils.dispatch(push(routeWithParams));
    }

    private static generateRoute(route: string, params: Dic): string {
        let routeStr = route;
        for ( const key in params ) {
            const param = key ? params[key] : undefined;
            if ( param ) {
                const searchValue: string = String(':' + key).trim();
                const replaceValue: string = String(param).trim();
                routeStr = routeStr.replace(searchValue, replaceValue);
            }
        }
        return routeStr;
    }

}
