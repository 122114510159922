import { ApiEndpoints } from "../../../../shared/infrastructure/ws/ApiEndpoints";
import { ContractEntity } from "../../../../shared/infrastructure/ws/ApiModel";
import { ApiRequest, ApiResponse } from "../../../../shared/infrastructure/ws/ApiRequest";
import { METHOD } from '@cuatroochenta/co-generic-request';



type Response = ApiResponse<ContractEntity>;


export class TaskSupportFinalizeCompanyContract extends ApiRequest<void, Response> {
	public constructor(uuid: string | number) {
		super(
			METHOD.POST,
			ApiRequest.generateRoute(ApiEndpoints.SUPPORT_COMPANY_FINALIZE_CONTRACT, {uuid})
		);
	}
}
