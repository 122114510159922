import * as React from 'react';
import classNames from 'classnames';
import { StyledProps } from '../StyledProps';

export interface Props {
    id?: string
    xs?: number
    sm?: number
    md?: number
    lg?: number
    auto?: boolean
}

export const PsrCol: React.FunctionComponent<Props & StyledProps> = (
    {
        id,
        xs,
        sm,
        md,
        lg,
        auto,
        className,
        style,
        children,
    }
) => {
    const classes = classNames({
        [`col-xs-${ xs }`]: xs,
        [`col-sm-${ sm }`]: sm,
        [`col-md-${ md }`]: md,
        [`col-lg-${ lg }`]: lg,
        [`${ className }`]: className,
        col: auto,
    });

    return (
        <div
            id={ id }
            className={ classes }
            style={ style }
            children={ children }
        />
    );
};

