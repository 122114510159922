import { ApiRequest, ApiResponse } from '../../../../modules/shared/infrastructure/ws/ApiRequest';
import { ApiEndpoints } from '../../../../modules/shared/infrastructure/ws/ApiEndpoints';
import { METHOD } from '@cuatroochenta/co-generic-request';

export default interface Upload {
    url: string,
    upload_file?: {
        id: string,
        name: string,
        url: string,
        size: number,
    }
}

export default class TaskFileUpload extends ApiRequest<FormData, ApiResponse<Upload>> {

    public constructor(
        private readonly file: File,
        private readonly companyId?: string,
    ) {
        super(METHOD.POST, ApiEndpoints.UPLOAD, false);
        this.setErrorMessage(450, "Fichero protegido");
    }

    protected getRequest(): FormData {
        const formData = new FormData();
        formData.append('file', this.file);
        if (this.companyId) {
            formData.append('company_id', this.companyId);
        }
        return formData;
    }
}
