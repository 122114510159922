import * as React from 'react';
import { CompanyEntity } from "../../../shared/infrastructure/ws/ApiModel";
import { PsrTableItemProps } from "../../../../components/psrTable/PsrTableItemProps";
import { I18nUtils } from "../../../../i18n/I18nUtils";
import { TR_CAMBIAR_PLAN, TR_CIUDAD, TR_EMAIL, TR_NIF, TR_NOMBRE, TR_PLAN, TR_PROVINCIA } from "../../../../i18n/constants";
import { PrsTableHeader } from "../../../../components/psrTable/PsrTable";
import { PsrButton } from '../../../../components/psrButton/PsrButton';
import classnames from 'classnames';
import { PsrIcon } from '../../../../components/psrIcon/PsrIcon';
import { PsrFaIcon } from '../../../../components/psrIcon/PsrFaIcon';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

export function CompanyTableItemView({ item, index, onClickItem, onClickMenu, onClickFinalize }: PsrTableItemProps<CompanyEntity>) {
    const className = classnames(index % 2 === 0 ? 'even' : 'odd', 'vertical-align-center')
    const classNameCompanyPlan = classnames('badge', 'badge-lg', item.company_plan && item.company_plan.id === '1' ? 'badge-accent' : 'badge-secondary')
    const isFullPlan = item.company_plan && item.company_plan.id === '1';
    return (
        <tr className={className}>
            <td>{item.nif}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.city}</td>
            <td>{item.province}</td>
            <td>{item.company_plan ? <span className={classNameCompanyPlan}>{item.company_plan.name}</span> : ''}</td>
            <td><PsrButton text={I18nUtils.tr(TR_CAMBIAR_PLAN)} onClick={() => onClickItem(item)} className={'btn btn-form-primary'}/></td>
            <td>
                {
                    isFullPlan &&
                    <Dropdown>
                        <Dropdown.Toggle className={'btn'}>
                            <PsrIcon icon={PsrFaIcon.CONFIGURATION}/>
                        </Dropdown.Toggle>
                        <DropdownMenu>
                            {!!item.company_contract &&
                                <DropdownItem
                                    onClick={() => onClickMenu(item)}
                                >{'Gestionar contrato'}</DropdownItem>
                            }
                            {!!item.company_contract &&
                                <DropdownItem
                                    onClick={() => onClickFinalize(item)}
                                >{'Rescindir contrato'}</DropdownItem>
                            }
                            {!item.company_contract &&
                                <DropdownItem
                                    onClick={() => onClickMenu(item)}
                                >{'Crear contrato'}</DropdownItem>
                            }
                        </DropdownMenu>

                    </Dropdown>

                }
            </td>
        </tr>

    );
}

export const CompanyTableItemViewHeaders: PrsTableHeader[] = [
    { name: I18nUtils.tr(TR_NIF) },
    { name: I18nUtils.tr(TR_NOMBRE) },
    { name: I18nUtils.tr(TR_EMAIL) },
    { name: I18nUtils.tr(TR_CIUDAD) },
    { name: I18nUtils.tr(TR_PROVINCIA) },
    { name: I18nUtils.tr(TR_PLAN) },
    { name: "" },
    { name: "" },

]

