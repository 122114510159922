import * as React from 'react';
import {Component, ReactNode} from 'react';
import {I18nUtils} from "../../i18n/I18nUtils";
import {AppConfig} from "../../AppConfig";
import {PsrIcon} from "../psrIcon/PsrIcon";
import {PsrFaIcon} from "../psrIcon/PsrFaIcon";
import {TR_ELEMENTOS, TR_IR_A_LA_PAGINA} from "../../i18n/constants";
import {ApiPager} from "../../modules/shared/infrastructure/ws/ApiRequest";

export interface PsrPagerProps {
    data: ApiPager,
    onPage: (page: number) => void,
    loading: boolean,
    onLimit?: (limit: number) => void,
    currentLimit?: number,
}

export default class PsrPager extends Component<PsrPagerProps> {

    public render(): ReactNode {
        const {data, onPage, currentLimit, onLimit, loading} = this.props;
        return (
            <>
                <div className="m-0 align-center">
                    <div className="pager-container">
                        <div className={"pagination-container"}>
                            <ul className="pagination paginator">
                                {this.renderFirstPage()}
                                {this.renderPreviousPage()}
                                {this.renderPageNumbers()}
                                {this.renderNextPage()}
                                {this.renderLastPage()}
                            </ul>
                        </div>

                        <div className="pager-selector-container">
                            <div className={"index-selector"}>
                                <select
                                    id="pagina"
                                    className="form-control pager-selector"
                                    value={data.current}
                                    onChange={(e) => {
                                        onPage(parseInt(e.target.value, 10))}}
                                    disabled={loading}
                                >
                                    {[...Array(data.total_pages).keys()].map((element, i) => <option key={i}
                                                                                                     value={i + 1}>{i + 1}</option>)}
                                </select>
                                <label htmlFor="pagina"
                                       className={"pager-text-selector"}>{I18nUtils.tr(TR_IR_A_LA_PAGINA)}:</label>
                            </div>

                            {currentLimit &&
                            <div className={"index-selector"}>
                                <select
                                    id="elementos"
                                    className="form-control pager-selector"
                                    value={currentLimit}
                                    onChange={(e) => {
                                        !!onLimit && onLimit(parseInt(e.target.value, 10))}}
                                    disabled={loading}
                                >
                                    {AppConfig.PAGER.limits.map((limit: number, index: number) => <option key={index}
                                                                                       value={limit}>{limit}</option>)}

                                </select>
                                <label htmlFor="pagina"
                                       className={"pager-text-selector"}>{`${I18nUtils.tr(TR_ELEMENTOS)}:`}</label>
                            </div>}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderPageNumbers(): ReactNode {
        const {data, currentLimit, loading} = this.props;
        const itemsPerPage = currentLimit ? currentLimit : AppConfig.PAGER.elementsPage;

        const firstCurrentItem = itemsPerPage * (data.current - 1) + 1;
        let lastCurrentItem = data.current * itemsPerPage;
        if (lastCurrentItem > data.total_elements) {
            lastCurrentItem = data.total_elements
        }
        return (
            <li className={"m-l-25 m-r-25 active"}>
                {loading ?
                    <span className={"secondary-color"}>...</span> :
                    `${firstCurrentItem} - ${lastCurrentItem} de ${data.total_elements}`}
            </li>
        )
    }

    private renderPreviousPage(): ReactNode {
        const {data, onPage, loading} = this.props;

        const isDisabled = loading || (data.current - 1 <= 0);
        const tagAprops = {className: "waves-effect", onClick: ()=>{},};
        if (!isDisabled) {
            tagAprops.onClick = () => {
                onPage(data.current - 1);
            };
        }

        return (
            <li className={`paginator ${isDisabled ? 'disabled' : 'pointer'}`}>
                <a {...tagAprops}>
                    <PsrIcon icon={PsrFaIcon.ANGLE_LEFT} className={"pager-icon-size m-l-10"}/>
                </a>
            </li>
        );
    }

    private renderNextPage(): ReactNode {
        const {data, onPage, loading} = this.props;

        const isDisabled = loading || (data.current + 1 > data.total_pages);
        const tagAprops = {className: "waves-effect", onClick: ()=>{},};
        if (!isDisabled) {
            tagAprops.onClick = () => {
                onPage(data.current + 1);
            };
        }

        return (
            <li className={`paginator ${isDisabled ? 'disabled' : 'pointer'}`}>
                <a {...tagAprops}>
                    <PsrIcon icon={PsrFaIcon.ANGLE_RIGHT} className={"pager-icon-size m-r-10"}/>
                </a>
            </li>
        );
    }

    private renderFirstPage(): React.ReactNode {
        const {data, onPage, loading} = this.props;

        const isDisabled = loading || (data.total_elements === 0 || data.current === 1);
        const tagAprops = {onClick: ()=>{},};
        if (!isDisabled) {
            tagAprops.onClick = () => {
                onPage(1);
            };
        }

        return (
            <li className={`paginator ${isDisabled ? 'disabled' : 'pointer'}`}>
                <a {...tagAprops}>
                    <PsrIcon icon={PsrFaIcon.ANGLE_DOUBLE_LEFT} className={"pager-icon-size"}/>
                </a>
            </li>
        );
    }

    private renderLastPage(): React.ReactNode {
        const {data, onPage, loading} = this.props;

        const isDisabled = loading || (data.total_elements === 0 || data.current === data.total_pages);
        const tagAprops = {
            onClick: ()=>{},
        };

        if (!isDisabled) {
            const lastPage = data.total_pages;
            tagAprops.onClick = () => {
                onPage(lastPage);
            };
        }

        return (
            <li className={`paginator ${isDisabled ? 'disabled' : 'pointer'}`}>
                <a {...tagAprops}>
                    <PsrIcon icon={PsrFaIcon.ANGLE_DOUBLE_RIGHT} className={"pager-icon-size"}/>
                </a>
            </li>
        );
    }

}
