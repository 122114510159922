import * as React from 'react';
import { UsersView } from "./UsersView";
import { useExportUserAcceptComercialCommunications } from '../application/UseExportUserAcceptComercialCommunications';

export function UsersDataContainer() {
    const { loading, exportExcel } = useExportUserAcceptComercialCommunications();
    return (
        <>
            <UsersView
                onExportAcceptComercialCommunications={exportExcel}
                loading={loading}
            />
        </>
    );
}
