import { METHOD } from "@cuatroochenta/co-generic-request";
import { ApiEndpoints } from "../../../../shared/infrastructure/ws/ApiEndpoints";
import { ContractEntity } from "../../../../shared/infrastructure/ws/ApiModel";
import { ApiRequest, ApiResponse } from "../../../../shared/infrastructure/ws/ApiRequest";


type Response = ApiResponse<ContractEntity>;


export class TaskSupportGetCompanyContract extends ApiRequest<void, Response> {
	public constructor(uuid: string | number) {
		super (
			METHOD.GET,
			ApiRequest.generateRoute(ApiEndpoints.SUPPORT_COMPANY_CONTRACT_INFO, { uuid })
		);

	}
}
