import * as React from 'react'
import { useMemo } from 'react'
import { ContractEntity, PutCompanyContractEntity } from '../../../shared/infrastructure/ws/ApiModel'
import { PsrButton } from '../../../../components/psrButton/PsrButton'
import { PsrCardBody } from '../../../../components/psrCard/PsrCardBody'
import { PsrCol } from '../../../../components/psrLayaout/PsrCol'
import { PsrRow } from '../../../../components/psrLayaout/PsrRow'
import { PsrLoadingView } from '../../../../components/psrLoadingView/PsrLoadingView'
import { PsrTextInput } from '../../../../components/psrTextInput/PsrTextInput'
import { DateUtils } from '../../../../utils/DateFormatter'
import { CreateOrUpdate } from './CompanyContractConfigDataContainer'
import './CompanyContractConfigView.scss';
import { PsrSwitch } from '../../../../components/psrSwitch/PsrSwitch';
import { FormFile } from '../../../../components/form-file/FormFile'
import Uuid from '../../../shared/domain/Uuid';
import { FileValue } from '@pavabits/form-drag-file/dist';
import { FormErrors } from '@pavabits/use-form';
import useForm from '@pavabits/use-form/dist/use-form';

interface Props {
    contract?: ContractEntity
    loading: boolean
    sendCreateContractRequest: (contract: PutCompanyContractEntity, type: string) => void,
    companyId: string,
}

export interface CompanyContractFormData {
    start_date: string,
    end_date: string | null,
    renewal_date: string | null,
    annual_invoices_limit: number,
    files: FileValue[],
    face_enabled: boolean,
    faceb2b_enabled: boolean,
    ocr_enabled: boolean,
    ocr_limit: number,
}

export const CompanyContractConfigView: React.FunctionComponent<Props> = ({
                                                                              contract,
                                                                              loading,
                                                                              sendCreateContractRequest,
                                                                              companyId,
                                                                          }) => {


    const handleOnSubmit = (valid: boolean, values: CompanyContractFormData, errors: FormErrors<PutCompanyContractEntity>) => {
        const isCreateOrisUpdate = contract ? CreateOrUpdate.update : CreateOrUpdate.create;
        const sendValues: PutCompanyContractEntity = {
            ...values,
            renewal_date: values.renewal_date ? DateUtils.stringToBackendDate(values.renewal_date.toString()) : null,
            end_date: values.end_date ? DateUtils.stringToBackendDate(values.end_date.toString()) : null,
            start_date: DateUtils.stringToBackendDate(values.start_date.toString()),
            ocr_enabled: values.ocr_enabled,
            ocr_limit: values.ocr_enabled ? (values.ocr_limit ?? 0) : 0,
            file: values.files[0]?.url || '',
            face_enabled: values.face_enabled,
            faceb2b_enabled: values.faceb2b_enabled,
        }
        if (valid) {
            sendCreateContractRequest(sendValues, isCreateOrisUpdate)
        }
    }

    const validate = (values: CompanyContractFormData): FormErrors<CompanyContractFormData> => {
        const errors: FormErrors<PutCompanyContractEntity> = {}

        if (values.start_date && !(values.start_date.toString().split('/').length === 3)) {
            errors.start_date = 'Fecha inválida'
        }
        if (values.renewal_date && !(values.renewal_date.toString().split('/').length === 3)) {
            errors.renewal_date = 'Fecha inválida'
        }
        if (values.end_date && !(values.end_date.toString().split('/').length === 3)) {
            errors.end_date = 'Fecha inválida'
        }
        if (values.annual_invoices_limit && !values.annual_invoices_limit.toString().match(/^[0-9]*$/)) {
            errors.annual_invoices_limit = 'Sólo se admiten números'
        }
        if (values.ocr_limit && !values.ocr_limit.toString().match(/^[0-9]*$/)) {
            errors.ocr_limit = 'Sólo se admiten números'
        }

        return errors
    }

    const faceModule = contract?.modules.find((module) => module.key === 'module_face');
    const faceB2BModule = contract?.modules.find((module) => module.key === 'module_faceb2b');
    const ocrModule = contract?.modules.find((module) => module.key === 'module_ocr');

    const initialValues = useMemo<CompanyContractFormData>(() => {
        const files: FileValue[] = [];
        if (contract && contract.file) {
            files.push({
                id: Uuid.generate(),
                name: contract?.file.split('/').pop() || '-',
                url: contract?.file,
                loading: false,
            })
        }
        return ({
            start_date: DateUtils.formatInputDateFromBackend(contract ? contract.start_date : new Date()),
            end_date: contract && contract.end_date ? DateUtils.formatInputDateFromBackend(contract.end_date) : null,
            renewal_date: contract && contract.renewal_date ? DateUtils.formatInputDateFromBackend(contract.renewal_date) : null,
            annual_invoices_limit: contract?.annual_invoices_limit || 0,
            files,
            face_enabled: faceModule?.enabled || false,
            faceb2b_enabled: faceB2BModule?.enabled || false,
            ocr_enabled: ocrModule?.enabled || false,
            ocr_limit: ocrModule?.limit || 0,
        })
    }, [contract]);

    console.log('INITIAL VALUES', initialValues)

    const { valid, errors, onField, onSubmitForm, values } = useForm({ initialValues, handleOnSubmit, validate });

    return (
        <div className={'screen-container p-l-15 p-r-15 p-b-30'}>

            <PsrLoadingView
                loading={loading}
            />
            <form onSubmit={onSubmitForm}>
                <PsrRow>
                    <PsrCol lg={12} md={12}>
                        <PsrCardBody className={'contract-section-card'}>
                            <PsrRow>
                                <h6>{'Contrato'}</h6>
                            </PsrRow>
                            <PsrRow>
                                <FormFile
                                    companyId={companyId}
                                    value={onField('files').value}
                                    onChange={onField('files').onChangeValue}
                                />
                            </PsrRow>
                        </PsrCardBody>
                    </PsrCol>
                    <PsrCol lg={6} md={6}>
                        <PsrCardBody className={'contract-section-card'}>
                            <PsrRow>
                                <h6>Contrato</h6>
                            </PsrRow>
                            <PsrRow>
                                <div className={'contract-section-item'}>
                                    <PsrTextInput
                                        value={onField('start_date').value}
                                        label={'Fecha inicio contrato'}
                                        labelFor={'start_date'}
                                        type={'text'}
                                        required={true}
                                        autoFocus={true}
                                        placeholder={'DD/MM/AAAA'}
                                        onChange={onField('start_date').onChangeValue}
                                    />
                                    <label className={'error d-flex'}>{errors.start_date}</label>
                                </div>
                                <div className={'contract-section-item'}>
                                    <PsrTextInput
                                        value={onField('end_date').value}
                                        label={'Fecha fin contrato'}
                                        labelFor={'end_date'}
                                        type={'text'}
                                        required={false}
                                        autoFocus={false}
                                        placeholder={'DD/MM/AAAA'}
                                        onChange={onField('end_date').onChangeValue}
                                    />
                                    <label className={'error d-flex'}>{errors.end_date}</label>
                                </div>
                                <div className={'contract-section-item'}>
                                    <PsrTextInput
                                        value={onField('renewal_date').value}
                                        label={'Fecha renovación contrato'}
                                        labelFor={'renewal_date'}
                                        type={'text'}
                                        required={false}
                                        autoFocus={false}
                                        placeholder={'DD/MM/AAAA'}
                                        onChange={onField('renewal_date').onChangeValue}
                                    />
                                    <label className={'error d-flex'}>{errors.renewal_date}</label>
                                </div>
                            </PsrRow>
                        </PsrCardBody>
                    </PsrCol>
                    <PsrCol lg={6} md={6}>
                        <PsrCardBody className={'contract-section-card'}>
                            <PsrRow>
                                <h6>{'Facturas'}</h6>
                            </PsrRow>
                            <PsrRow>
                                <div className={'contract-section-item'}>
                                    <PsrTextInput
                                        value={onField('annual_invoices_limit').value}
                                        label={'Límite de facturas anuales'}
                                        labelFor={'annual_invoices_limit'}
                                        type={'text'}
                                        required={true}
                                        autoFocus={false}
                                        placeholder={'Introducir límite de facturas anuales'}
                                        onChange={onField('annual_invoices_limit').onChangeValue}
                                    />
                                    <label className={'error d-flex'}>{errors.annual_invoices_limit}</label>
                                </div>
                                <div className={'contract-section-item p-r-20'}>
                                    <label>Facturas subidas</label>
                                    <p style={{ marginTop: 5 }}>{contract && contract.annual_invoices_current !== null ? contract.annual_invoices_current : '-'}</p>

                                </div>
                            </PsrRow>
                        </PsrCardBody>
                    </PsrCol>
                    <PsrCol lg={12} md={12}>
                        <PsrCardBody className={'contract-section-card'}>
                            <PsrRow>
                                <h6>{'Módulos'}</h6>
                            </PsrRow>
                            <PsrRow>
                                <PsrCol lg={4} md={6}>
                                    <div className={'contract-section-item'}>
                                        <span>{'Face'}</span>
                                        <PsrSwitch
                                            checked={onField('face_enabled').value}
                                            onChange={(e: any) => onField('face_enabled').onChangeValue(e.target.checked)}
                                        />
                                    </div>
                                </PsrCol>
                                <PsrCol lg={4} md={6}>
                                    <div className={'contract-section-item'}>
                                        <span>{'Face B2B'}</span>
                                        <PsrSwitch
                                            checked={onField('faceb2b_enabled').value}
                                            onChange={(e: any) => onField('faceb2b_enabled').onChangeValue(e.target.checked)}
                                        />
                                    </div>
                                </PsrCol>
                                <PsrCol lg={4} md={6}>
                                    <div className={'contract-section-item'}>
                                        <span>{'OCR'}</span>

                                        <PsrSwitch
                                            checked={onField('ocr_enabled').value}
                                            onChange={(e: any) => onField('ocr_enabled').onChangeValue(e.target.checked)}
                                        />

                                        {values.ocr_enabled &&
                                        <div>
                                            <div className={'m-10'}>
                                                <PsrTextInput
                                                    value={onField('ocr_limit').value}
                                                    label={'Límite de páginas para el OCR'}
                                                    labelFor={'ocr_pages'}
                                                    type={'text'}
                                                    required={true}
                                                    autoFocus={false}
                                                    placeholder={'Introducir el límite'}
                                                    onChange={onField('ocr_limit').onChangeValue}
                                                />
                                            </div>
                                            <div className={'m-10'}>
                                                <label className={'error d-flex'}>{errors.ocr_limit}</label>
                                                <label>Páginas subidas al OCR</label>
                                                <p>{ocrModule?.current !== null ? ocrModule?.current : '-'}</p>

                                            </div>
                                        </div>
                                        }
                                    </div>
                                </PsrCol>
                            </PsrRow>
                        </PsrCardBody>
                    </PsrCol>
                </PsrRow>

                <div className={'contract-submit-button'}>
                    <PsrButton
                        className={'btn btn-primary d-flex'}
                        text={!!contract ? 'Actualizar contrato' : 'Crear contrato'}
                        type={'submit'}
                        disabled={!valid}
                    />
                </div>
            </form>
        </div>
    )
}
