import * as React from 'react';
import { LoginContainer } from './LoginContainer';

export class LoginScreen extends React.Component {

    public render(): React.ReactNode {
        return <LoginContainer/>;
    }

}
