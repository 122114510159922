import * as React from 'react';
import classNames from 'classnames';
import { StyledProps } from '../StyledProps';
import { PsrFaIcon } from './PsrFaIcon';

interface Props {
    icon: PsrFaIcon
    children?: never
    onClick?: () => void
}

export const PsrIcon: React.FunctionComponent<Props & StyledProps> = (
    {
        icon,
        style,
        className,
        onClick,
    }
) => {
    const classes = classNames('material-icons', className, icon);

    return (
        <i
            style={ style }
            className={ classes }
            onClick={ onClick }
        />
    );
};
