export enum PsrFaIcon {
    INVOICE_RECEIVE = 'fas fa-inbox',
    INVOICE_SEND = 'fas fa-paper-plane',
    COST_CENTER = 'fas fa-boxes',
    USERS = 'fas fa-users',
    KEY_INDICATORS = 'fas fa-chart-bar',
    DOCUMENT = 'fas fa-file',
    PROVIDER = 'fas fa-briefcase',
    CALENDAR = 'fas fa-calendar',
    EDIT = 'fas fa-pen',
    DELETE = 'fas fa-trash-alt',
    ADD = 'fas fa-plus',
    CLOSE = 'fas fa-times',
    CONFIGURATION = 'fas fa-cog',
    MY_COMPANIES = 'fas fa fa-building',
    FAQ = 'fas fa-question-circle',
    USER = 'fas fa-user',
    MENU = 'fas fa-ellipsis-v',
    PLUS = 'fas fa-plus',
    MINUS = 'fas fa-minus',
    EXTERNAL_LINK = 'fas fa-external-link-alt',
    ARROW_UP = 'fas fa-chevron-up',
    ARROW_DOWN = 'fas fa-chevron-down',
    ARROW_RIGHT = 'fas fa-chevron-right',
    ARROW_MOVE_UP = 'fas fa-arrow-up',
    ARROW_MOVE_DOWN = 'fas fa-arrow-down',
    ARROW_ADD_ALL = 'fas fa-reply',
    ANGLE_LEFT = 'fas fa-angle-left',
    ANGLE_RIGHT = 'fas fa-angle-right',
    ANGLE_DOUBLE_RIGHT = 'fas fa-angle-double-right',
    ANGLE_DOUBLE_LEFT = 'fas fa-angle-double-left',
    PAPERCLIP = 'fas fa-paperclip',
    COMMENT = 'far fa-comment-alt',
    CHECK = 'fas fa-check',
    ACTION_REQUIRE = 'fas fa-exclamation-triangle',
    DRAG = 'fas fa-grip-vertical',
    PDF_DOCUMENT = 'fas fa-file-invoice',
    LOGS = 'fas fa-bell',
    INFORMATION = 'fas fa-info-circle',
    QUESTION = 'fas fa-question',
    BELL = 'fas fa-bell',
    CIRCLE = 'fas fa-circle',
    USER_ROLE = 'fas fa-user-tag',
    ONLY_READ = 'fas fa-eye',
    ROTATE_RIGHT = 'fas fa-share',
    ROTATE_LEFT = 'fas fa-reply',
    SORT = 'fas fa-sort',
    COPY = 'fas fa-copy',
    CAMERA = 'fas fa-camera',
    PAYMENT = 'fas fa-credit-card',
}
