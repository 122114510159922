import * as React from 'react';
import { FC, useState } from 'react';
import { LoginTemplate } from './LoginTemplate';
import { LoginData } from '../domain/login-data';
import { AuthManagerInstance } from './auth-manager';
import { PsrAlert } from '../../../components/psrAlert/PsrAlert';
import { Router } from '../../../routing/Router';
import { Routes } from '../../../routing/Routes';
import ApiAuthRepository from '../infrastructure/api/api.auth.repository';

export const LoginContainer: FC = () => {
    const authRepository = new ApiAuthRepository();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const onLogin = (email: string, password: string, rememberMe: boolean) => {
        setError('')
        setLoading(true);
        authRepository.login(email, password)
            .then((token) => {
                const loginData = new LoginData(token, rememberMe);
                AuthManagerInstance.login(loginData);
                Router.goToRoute(Routes.COMPANY_LIST)
            })
            .catch((error) => {
                PsrAlert.error(error)
                setError(error)
            })
            .finally(() => setLoading(false))
    };

    return (
        <LoginTemplate
            loading={loading}
            error={error}
            onLogin={onLogin}
        />
    );
}
