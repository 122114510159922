import { UserSimplyEntity } from '../../modules/shared/infrastructure/ws/ApiModel';

export class User {

    constructor(private entity: UserSimplyEntity) {}

    public get name(): string {
        return `${ this.entity.name } ${ this.entity.last_name }`;
    }

    public get image(): string | undefined {
        return this.entity.image;
    }

}
