import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { PsrSidebarItem } from './PsrSidebarItem';
import { PsrFaIcon } from '../../psrIcon/PsrFaIcon';
import { Routes } from '../../../routing/Routes';
import { SidebarItemIds } from './ActiveSidebarItems';
import { useLocation } from 'react-router';
import { I18nUtils } from '../../../i18n/I18nUtils';
import { TR_EMPRESAS, TR_USUARIOS } from '../../../i18n/constants';

interface Props {
    collapsed: boolean
    noScrollVertical: boolean
    noScrollHorizontal: boolean
}

export const PsrSidebar: React.FunctionComponent<Props> = (
    {
        collapsed,
        noScrollVertical,
        noScrollHorizontal,
        children,
    }
) => {
    const { pathname } = useLocation();
    const [ displayScrollBar, setDisplayScrollBar ] = useState<boolean>(false);

    const menuClasses = classNames(
        `${ displayScrollBar ? 'main-scrollbar' : 'main-scrollbar-hidden' }`,
        `${ collapsed ? 'sidebar-collapsed' : 'sidebar-expanded' }`
    );

    const mainClasses = classNames(
        'main-content',
        { 'no-scroll-vertical': noScrollVertical },
        { 'no-scroll-horizontal': noScrollHorizontal },
    );

    return (
        <div id="body-wrapper">
            <div
                id="sidebar"
                className={ menuClasses }
                onMouseEnter={ () => setDisplayScrollBar(true) }
                onMouseLeave={ () => setDisplayScrollBar(false) }
            >
                <ul className="list-group">
                    <PsrSidebarItem
                        id={ SidebarItemIds.COMPANY_LIST }
                        icon={ PsrFaIcon.PROVIDER }
                        label={ I18nUtils.tr(TR_EMPRESAS) }
                        route={ Routes.COMPANY_LIST }
                        count={ undefined }
                        pathname={ pathname }
                        borderBottom={ undefined }
                        collapsed={ collapsed }
                    />
                    <PsrSidebarItem
                        id={ SidebarItemIds.USERS }
                        icon={ PsrFaIcon.USER }
                        label={ I18nUtils.tr(TR_USUARIOS) }
                        route={ Routes.USERS }
                        count={ undefined }
                        pathname={ pathname }
                        borderBottom={ undefined }
                        collapsed={ collapsed }
                    />
                </ul>
                <ul className="list-group sidebar-content-bottom"/>
            </div>
            <div id="content" className={ mainClasses }>
                { children }
            </div>
        </div>
    );
};
