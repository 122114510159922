import {useState} from "react";
import {CompanyEntity} from "../../../../shared/infrastructure/ws/ApiModel";
import {PsrAlert} from "../../../../../components/psrAlert/PsrAlert";
import {I18nUtils} from "../../../../../i18n/I18nUtils";
import {TR_PLAN_DE_EMPRESA_ACTUALIZADO_CORRECTAMENTE} from "../../../../../i18n/constants";
import CompanyPlanRepository from '../../domain/company-plan.repository';

export default function useCompanyPlan(companyPlanRepository: CompanyPlanRepository, updateCompany: (company: CompanyEntity) => void) {

    const companyPlans = [
        {value: '1', name: 'Full'},
        {value: '2', name: 'Lite'},
    ]
    const [companyChangePlan, setCompanyChangePlan] = useState<CompanyEntity | undefined>(undefined);
    const [companyChangePlanLoading, setCompanyChangePlanLoading] = useState<boolean>(false);

    const setPlan = (companyPlanId: string, companyId: string) => {
        setCompanyChangePlanLoading(true)
        companyPlanRepository.update(companyId, companyPlanId)
            .then((companyEntity) => {
                setCompanyChangePlan(undefined)
                PsrAlert.success(I18nUtils.tr(TR_PLAN_DE_EMPRESA_ACTUALIZADO_CORRECTAMENTE))
                updateCompany(companyEntity)
            })
            .catch((error) => PsrAlert.error(error))
            .finally(() => setCompanyChangePlanLoading(false))
    }

    return ({
        companyPlans,
        companyChangePlan, setCompanyChangePlan,
        companyChangePlanLoading,
        setPlan,
    });

}
