import * as React from 'react';
import classNames from 'classnames';
import { StyledProps } from '../StyledProps';

interface Props {}

export const PsrCardBody: React.FunctionComponent<Props & StyledProps> = (
    {
        className,
        style,
        children,
    }
) => {
    const classComponent = classNames('body', className);

    return (
        <div
            className={ classComponent }
            style={ style }
        >
            { children }
        </div>
    );
};
