import { METHOD } from '@cuatroochenta/co-generic-request';
import { ApiRequest, ApiResponse } from '../../../../shared/infrastructure/ws/ApiRequest';
import { CompanyEntity } from '../../../../shared/infrastructure/ws/ApiModel';
import { ApiEndpoints } from '../../../../shared/infrastructure/ws/ApiEndpoints';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - REQUESTS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface SupportCompaniesSetPlanRequest {
    company_plan_id: string
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TYPES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

type Request = SupportCompaniesSetPlanRequest;
type Response = ApiResponse<CompanyEntity>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TASKS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class TaskSupportSetPlantToCompany extends ApiRequest<Request, Response> {

    private readonly request: Request;

    public constructor(uuid: string | number, body: Request) {
        super(
            METHOD.POST,
            ApiRequest.generateRoute(ApiEndpoints.SUPPORT_SET_PLANT_TO_COMPANY, { uuid })
        );
        this.request = body;
    }

    protected getRequest(): Request {
        return this.request;
    }

}
