import { I18nUtils } from '../../../../i18n/I18nUtils';
import {
    TR_EL_ELEMENTO_NO_EXISTE,
    TR_FALTAN_DATOS,
    TR_NO_TIENES_PERMISOS_PARA_REALIZAR_ESTA_ACCION,
    TR_OPERACION_NO_PERMITIDA,
    TR_SE_HA_PRODUCIDO_UN_ERROR_OBTENIENDO_INFORMACION
} from '../../../../i18n/constants';
import { AuthManagerInstance } from '../../../auth/application/auth-manager';
import { METHOD, ReduxAwareGenericRequest } from '@cuatroochenta/co-generic-request';
import { ReduxStorageUtils } from '@cuatroochenta/co-redux-builders';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface ApiCriteriaRequest {
    page?: number    // Default: 1
    limit?: number   // Default: 10
    search?: string  // Default: ''
    sort?: string    // Example: '+column_name | -column_name'
}

export interface ApiResponse<T> {
    success: boolean
    message: string
    code?: number
    data: T
    pager?: ApiPager
}

export interface ApiPager {
    current: number
    total_pages: number
    total_elements: number
}

export interface ApiBatchResponseCode {
    200?: number
    401?: number
    404?: number
    405?: number
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - REQUEST
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export abstract class ApiRequest<Request = {}, Response = {}>
    extends ReduxAwareGenericRequest<Request, Response> {

    protected constructor(method: METHOD, url: string, addHeaderContentTypeApplicationJson = true) {
        super(method, url, I18nUtils.tr(TR_SE_HA_PRODUCIDO_UN_ERROR_OBTENIENDO_INFORMACION), ReduxStorageUtils.dispatch);
        if ( AuthManagerInstance.isLogged() ) {
            this.addHeader('Authorization', `Bearer ${ AuthManagerInstance.getAuthToken() }`);
        }
        this.addHeader('Accept', 'application/json');
        addHeaderContentTypeApplicationJson && this.addHeader('Content-type', 'application/json');

        this.setErrorMessage(400, I18nUtils.tr(TR_FALTAN_DATOS));
        this.setErrorMessage(404, I18nUtils.tr(TR_EL_ELEMENTO_NO_EXISTE));
        this.setErrorMessage(401, I18nUtils.tr(TR_NO_TIENES_PERMISOS_PARA_REALIZAR_ESTA_ACCION));
        this.setErrorMessage(405, I18nUtils.tr(TR_OPERACION_NO_PERMITIDA));

        this.enableLogs(false);
    }

    protected isResponseSuccess(response: { status: number; _bodyText?: string }): boolean {
        let success = super.isResponseSuccess(response);
        const responseBody = JSON.parse(response._bodyText || '{}');
        success = success || responseBody.success;
        return success;
    }

    protected parseResponse(responseObj: any): Response {
        if ( this.errorMessages[responseObj.code] ) throw new Error(this.errorMessages[responseObj.code]);
        if ( !responseObj.success ) throw new Error(responseObj.errorMessage);
        return super.parseResponse(responseObj);
    }

    protected parseError(errorObj: string): string {
        try {
            return JSON.parse(errorObj).message || super.parseError(errorObj);
        } catch ( ignored ) {
            return super.parseError(errorObj).toString();
        }
    }

    public static generateRoute(route: string, params: { [key: string]: number | string } = {}): string {
        let routeStr = route;
        for ( const key in params ) {
            if ( key && params[key] ) {
                const searchValue: string = String(':' + key).trim();
                const replaceValue: string = String(params[key]).trim();
                routeStr = routeStr.replace(searchValue, replaceValue);
            }
        }
        return routeStr;
    }

}
