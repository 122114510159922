import * as React from 'react';
import classNames from 'classnames';
import { StyledProps } from '../StyledProps';
import { Modal } from 'react-bootstrap';
import { PsrLoadingView } from '../psrLoadingView/PsrLoadingView';
import { PsrRow } from '../psrLayaout/PsrRow';
import { PsrButton } from '../psrButton/PsrButton';
import { I18nUtils } from '../../i18n/I18nUtils';
import { TR_ACEPTAR, TR_CANCELAR } from '../../i18n/constants';

interface Props {
    display: boolean
    title: string
    loading?: boolean
    message?: string | React.ReactNode
    bodyClasses?: string
    contentSize?: 'sm' | 'lg' | 'xl'
    cancelLabel?: string
    confirmLabel?: string
    confirmDisabled?: boolean
    onClose: () => void
    onCancel?: () => void
    onConfirm: (values?: any) => void
}

export const PsrConfirmModal: React.FunctionComponent<Props & StyledProps> = (
    {
        title,
        message,
        cancelLabel = I18nUtils.tr(TR_CANCELAR),
        confirmLabel = I18nUtils.tr(TR_ACEPTAR),
        confirmDisabled = false,
        bodyClasses = 'align-center',
        contentSize,
        loading = false,
        display,
        onClose,
        onCancel,
        onConfirm,
        children,
    }
) => {
    const messageClasses = classNames('modal-subtitle', { 'p-b-0': !children });

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const handleCancel = () => {
        !!onCancel && onCancel();
        onClose();
    };

    return (
        <Modal
            onHide={ onClose }
            show={ display }
            size={ contentSize }
        >
            {
                loading ? <PsrLoadingView loading={ true }/> : null
            }
            <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="signin-title">
                    { title }
                </Modal.Title>
            </Modal.Header>
            {
                message ? (
                    <Modal.Title className={ messageClasses }>
                        { message }
                    </Modal.Title>
                ) : null
            }
            {
                children ? (
                    <Modal.Body className={ bodyClasses }>
                        { children }
                    </Modal.Body>
                ) : null
            }
            <Modal.Footer>
                <PsrRow className="form-footer-container">
                    <PsrButton
                        text={ cancelLabel }
                        onClick={ handleCancel }
                        type={ 'button' }
                        className="btn btn-lg btn-form float-right btn-cancel"
                    />
                    <PsrButton
                        text={ confirmLabel }
                        onClick={ handleConfirm }
                        type={ 'button' }
                        className="btn btn-lg btn-form float-right m-l-15 btn-submit"
                        disabled={ confirmDisabled }
                    />
                </PsrRow>
            </Modal.Footer>
        </Modal>
    );
};
