import * as React from 'react';
import classNames from 'classnames';
import { BreadcrumbManager } from '../psrBreadcrumb/BreadcrumbManager';
import { PsrRow } from '../../psrLayaout/PsrRow';
import { PsrCol } from '../../psrLayaout/PsrCol';
import { PsrBreadcrumb } from '../psrBreadcrumb/PsrBreadcrumb';
import { StyledProps } from '../../StyledProps';
import { PsrButton } from '../../psrButton/PsrButton';

export interface HeaderAction {
    text: string
    visible: boolean
    onClick: () => void
}

interface Props {
    breadcrumbManager?: BreadcrumbManager
    breadcrumbActions?: HeaderAction[]
    receivePaginator?: React.ReactNode
    noScrollVertical: boolean
    noScrollHorizontal: boolean
}

export const PsrContent: React.FunctionComponent<Props & StyledProps> = (
    {
        breadcrumbManager,
        breadcrumbActions,
        receivePaginator,
        noScrollVertical,
        noScrollHorizontal,
        className,
        children,
    }
) => {
    const breadcrumbColSize = breadcrumbActions || receivePaginator ? 7 : 12;
    const colPaginator = 1;
    const colButtons = 4;
    const sectionClasses = classNames(
        'section',
        className,
        { 'no-scroll-vertical': noScrollVertical },
        { 'no-scroll-horizontal': noScrollHorizontal },
    );

    return (
        <section className={ sectionClasses }>
            {
                breadcrumbManager ? (
                    <PsrRow className="container-breadcrumb">
                        <PsrCol
                            xs={ breadcrumbColSize }
                            sm={ breadcrumbColSize }
                            md={ breadcrumbColSize }
                            lg={ breadcrumbColSize }
                        >
                            <PsrBreadcrumb breadcrumbManager={ breadcrumbManager }/>
                        </PsrCol>
                        {
                            receivePaginator ? (
                                <PsrCol
                                    xs={ colPaginator }
                                    sm={ colPaginator }
                                    md={ colPaginator }
                                    lg={ colPaginator }
                                >
                                    { receivePaginator }
                                </PsrCol>
                            ) : null
                        }
                        {
                            breadcrumbActions ? (
                                <PsrCol
                                    xs={ colButtons }
                                    sm={ colButtons }
                                    md={ colButtons }
                                    lg={ colButtons }
                                    className="header-actions"
                                >
                                    {
                                        breadcrumbActions.map((headerAction, index) => {
                                            const {
                                                text,
                                                visible,
                                                onClick,
                                            } = headerAction;

                                            if ( !visible ) return null;

                                            return (
                                                <PsrButton
                                                    key={ index }
                                                    text={ text }
                                                    className={ `btn btn-header-primary ${ index > 0 ? ' m-l-10' : '' }` }
                                                    onClick={ onClick }
                                                />
                                            );
                                        })
                                    }
                                </PsrCol>
                            ) : null
                        }
                    </PsrRow>
                ) : null
            }
            { children }
        </section>
    );
};
