import AuthRepository from '../../domain/auth.repository';
import { TaskSupportLogin } from '../ws/TaskSupportLogin';

export default class ApiAuthRepository implements AuthRepository {
    public async login(email: string, password: string): Promise<string> {
        return new TaskSupportLogin({email, password})
            .executeAsPromise()
            .then((response) => response.data)
    }
}
