import * as React from 'react';
import { useEffect } from 'react';
import { PutCompanyContractEntity } from '../../../shared/infrastructure/ws/ApiModel';
import { CompanyContractConfigView } from './CompanyContractConfigView';
import useCompanyContract from './hooks/useCompanyContract';
import { PsrLoadingView } from '../../../../components/psrLoadingView/PsrLoadingView';

interface Props {
    companyId: string
}

export enum CreateOrUpdate {
    create = 'CREATE',
    update = 'UPDATE'
}

export const CompanyContractConfigDataContainer: React.FunctionComponent<Props> = ({ companyId }) => {
    const { companyContract, companyContractLoading, getCompanyContract, createCompanyContract, updateCompanyContract } = useCompanyContract();

    useEffect(() => {
        getCompanyContract(companyId)
    }, [])

    const sendCreateContractRequest = (contract: PutCompanyContractEntity, requestType: string) => {
        switch (requestType) {
            case CreateOrUpdate.create: {
                createCompanyContract(companyId, contract);
                break;
            }
            case CreateOrUpdate.update: {
                updateCompanyContract(companyId, contract)
                break;
            }
        }
    }

    return (
        <>
            <CompanyContractConfigView
                contract={companyContract}
                loading={companyContractLoading}
                sendCreateContractRequest={sendCreateContractRequest}
                companyId={companyId}
            />
        </>
    )
}
