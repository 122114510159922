import { useEffect, useState } from "react";
import { CompanyEntity } from "../../../../shared/infrastructure/ws/ApiModel";
import { ApiPager } from "../../../../shared/infrastructure/ws/ApiRequest";
import { PsrAlert } from "../../../../../components/psrAlert/PsrAlert";
import useDebounce from "../../../../../hooks/UseDebounce";
import CompanyRepository from '../../domain/company.repository';

export default function useCompanies(companyRepository: CompanyRepository) {
    const [companies, setCompanies] = useState<CompanyEntity[]>([]);
    const [companiesLoading, setCompaniesLoading] = useState<boolean>(false);
    const [pager, setPager] = useState<ApiPager>({ current: 1, total_pages: 0, total_elements: 0 });
    const [search, setSearch] = useState<string>("");

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        setCompaniesLoading(true)
        companyRepository.get(pager.current, debouncedSearch)
            .then((response) => {
                setCompanies(response.companies);
                setPager(response.pager)
            })
            .catch((error: string) => {
                PsrAlert.error(error)
            })
            .finally(() => setCompaniesLoading(false))
    }, [pager.current, debouncedSearch]);

    const setPage = (page: number) => setPager({ ...pager, current: page });

    const updateCompany = (companyUpdate: CompanyEntity) => {
        setCompanies((prevState) => {
            return prevState.map((company) => company.id === companyUpdate.id ? companyUpdate : company)
        })
    }

    return { companies, companiesLoading, pager, setPage, search, setSearch, updateCompany }
}
