import * as React from 'react';
import { Component, ReactNode } from 'react';
import { default as AlertView } from 'react-s-alert';
import { TR_ERROR_INESPERADO, TR_FUNCIONALIDAD_NO_DISPONIBLE } from '../../i18n/constants';
import { I18nUtils } from '../../i18n/I18nUtils';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';

export class PsrAlert extends Component {

    public static info(message: string) {
        AlertView.info(message);
    }

    public static success(message: string) {
        AlertView.success(message);
    }

    public static warning(message: string) {
        AlertView.warning(message);
    }

    public static error(message: string) {
        const isString: boolean = typeof message === 'string'; // Pueden llegar errores no string en casos remotos
        AlertView.error(isString ? message : I18nUtils.tr(TR_ERROR_INESPERADO));
    }

    public static functionNoAvailable() {
        AlertView.warning(I18nUtils.tr(TR_FUNCIONALIDAD_NO_DISPONIBLE));
    }

    public render(): ReactNode {
        return (
            <div data-cy={ 'alert-component' }>
                <AlertView
                    stack={ { limit: 5 } }
                    position={ 'top-right' }
                    effect={ 'genie' }
                    timeout={ 10000 }
                    offset={ 60 }
                />
            </div>
        );
    }

}

