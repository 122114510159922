import * as React from 'react';

interface Props {
    onClickCollapse: () => void
}

export const PsrTopbarCollapser: React.FunctionComponent<Props> = (
    {
        onClickCollapse,
    }
) => {
    return (
        <div className="collapser-menu">
            <i
                className="fas fa-bars"
                onClick={ onClickCollapse }
            />
        </div>
    );
};
