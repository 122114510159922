import * as React from 'react';
import { AuthManagerInstance } from '../../modules/auth/application/auth-manager';
import { Router } from '../../routing/Router';
import { Routes } from '../../routing/Routes';
import { PsrSidebar } from './psrSidebar/PsrSidebar';
import { PsrTopbar } from './psrTopbar/PsrTopbar';
import { BreadcrumbManager } from './psrBreadcrumb/BreadcrumbManager';
import { PsrContent } from './psrContent/PsrContent';
import { PsrConfirmModal } from '../psrConfirmDialog/PsrConfirmDialog';
import { I18nUtils } from '../../i18n/I18nUtils';
import { TR_CERRAR_SESION, TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESION } from '../../i18n/constants';
import { TaskUserRoleCompanyCurrentList } from '../../modules/user/infrastructure/ws/TaskUserRoleCompanyCurrentList';
import { User } from './User';
import { PsrAlert } from '../psrAlert/PsrAlert';
import { PsrLoadingView } from '../psrLoadingView/PsrLoadingView';

interface Props {
    breadcrumbManager?: BreadcrumbManager
    noScrollVertical?: boolean
    noScrollHorizontal?: boolean
}

interface State {
    user: User | null
    loading: boolean
    sidebarCollapsed: boolean
    displayLogoutDialog: boolean
}

export class PsrDashboard extends React.Component<Props, State> {

    private readonly mql;

    public constructor(props: Props) {
        super(props);
        this.mql = window.matchMedia(`(max-width: 758px)`);
        this.state = {
            user: null,
            loading: false,
            sidebarCollapsed: false,
            displayLogoutDialog: false,
        };
    }

    componentDidMount() {
        this.mql.addEventListener('change', this.handleMediaQueryChanged);
        this.handleFetchUser();
    }

    componentWillUnmount() {
        this.mql.removeEventListener('change', this.handleMediaQueryChanged);
    }

    componentDidUpdate() {
        if ( !AuthManagerInstance.isLogged() ) Router.goToRoute(Routes.AUTH_LOGIN);
    }

    public render(): React.ReactNode {
        const {
            user,
            loading,
            sidebarCollapsed,
            displayLogoutDialog,
        } = this.state;

        const {
            breadcrumbManager,
            noScrollVertical = false,
            noScrollHorizontal = false,
            children
        } = this.props;

        if ( loading ) return <PsrLoadingView loading={ true }/>;

        if ( !user ) return null;

        return (
            <>
                <PsrTopbar
                    user={ user }
                    onClickCollapse={ this.handleClickCollapse }
                    onClickBrand={ this.handleClickBrand }
                    onLogout={ this.handleOpenLogoutDialog }
                />
                <PsrSidebar
                    collapsed={ sidebarCollapsed }
                    noScrollVertical={ noScrollVertical }
                    noScrollHorizontal={ noScrollHorizontal }
                >
                    <PsrContent
                        breadcrumbManager={ breadcrumbManager }
                        breadcrumbActions={ undefined }
                        receivePaginator={ undefined }
                        noScrollVertical={ noScrollVertical }
                        noScrollHorizontal={ noScrollHorizontal }
                    >
                        { children }
                    </PsrContent>
                </PsrSidebar>
                <PsrConfirmModal
                    display={ displayLogoutDialog }
                    title={ I18nUtils.tr(TR_CERRAR_SESION) }
                    message={ I18nUtils.tr(TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESION) }
                    confirmLabel={ I18nUtils.tr(TR_CERRAR_SESION) }
                    onConfirm={ this.handleLogout }
                    onClose={ this.handleCloseLogoutDialog }
                />
            </>
        );
    }

    private handleClickCollapse = () => {
        this.setState(prevState => ( { sidebarCollapsed: !prevState.sidebarCollapsed } ));
    };

    private handleClickBrand = () => {
        Router.goToRoute(Routes.COMPANY_LIST);
    };

    private handleLogout = () => {
        AuthManagerInstance.logout();
        Router.goToRoute(Routes.AUTH_LOGIN);
    };

    private handleOpenLogoutDialog = () => {
        this.setState({ displayLogoutDialog: true });
    };

    private handleCloseLogoutDialog = () => {
        this.setState({ displayLogoutDialog: false });
    };

    private handleMediaQueryChanged = () => {
        if ( this.mql.matches ) this.setState({ sidebarCollapsed: true });
        else this.setState({ sidebarCollapsed: false });
    };

    private handleFetchUser = () => {
        this.setState({ loading: true });
        this.fetchUser()
            .then(user => this.setState({ user }))
            .catch(message => PsrAlert.error(message))
            .finally(() => this.setState({ loading: false }));
    };

    private fetchUser = async(): Promise<User> => {
        const res = await new TaskUserRoleCompanyCurrentList().executeAsPromise();
        return new User(res.data[0].user);
    };

}
