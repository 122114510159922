import { METHOD } from '@cuatroochenta/co-generic-request';
import { ApiCriteriaRequest, ApiRequest, ApiResponse } from '../../../../shared/infrastructure/ws/ApiRequest';
import { CompanyEntity } from '../../../../shared/infrastructure/ws/ApiModel';
import { ApiEndpoints } from '../../../../shared/infrastructure/ws/ApiEndpoints';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TYPES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

type Request = ApiCriteriaRequest;
type Response = ApiResponse<CompanyEntity[]>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TASKS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class TaskSupportCompanies extends ApiRequest<Request, Response> {

    private readonly request: Request;

    public constructor(body: Request) {
        super(
            METHOD.POST,
            ApiEndpoints.SUPPORT_COMPANIES,
        );
        this.request = body;
    }

    protected getRequest(): Request {
        return this.request;
    }

}
