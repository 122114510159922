import { METHOD } from "@cuatroochenta/co-generic-request";
import { ApiEndpoints } from "../../../../shared/infrastructure/ws/ApiEndpoints";
import { ApiRequest, ApiResponse } from "../../../../shared/infrastructure/ws/ApiRequest";






export interface SupporCompanyUpdateContractRequest {
	start_date: Date,
	renewal_date: Date | null,
	end_date: Date | null,
	annual_invoices_limit: number,
	file: string,
	face_enabled: boolean,
	faceb2b_enabled: boolean,
	ocr_enabled: boolean,
	ocr_limit: number
}


type Request = SupporCompanyUpdateContractRequest;
type Response = ApiResponse<any>;

export class TaskSupportUpdateContract extends ApiRequest<Request, Response> {
	private readonly request: Request;

	public constructor(uuid: string | number, body: Request) {
		super(
			METHOD.PUT,
			ApiRequest.generateRoute(ApiEndpoints.SUPPORT_COMPANY_CONTRACT_INFO, { uuid })
		);
		this.request = body;
	}

	protected getRequest(): Request {
		return this.request;
	}
}
