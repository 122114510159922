import { Routes } from '../../../routing/Routes';
import { matchPath } from 'react-router';

export enum SidebarItemIds {
    COMPANY_LIST = 'company-list',
    USERS = 'users',
}

type RouteDic = { [key: string]: string[] }

class ActiveSidebarItems {

    private static INSTANCE = new ActiveSidebarItems();
    private routes: RouteDic;

    public static getInstance() {
        return ActiveSidebarItems.INSTANCE;
    }

    private constructor() {
        this.routes = {
            [SidebarItemIds.COMPANY_LIST]: [ Routes.COMPANY_LIST, Routes.COMPANY_CONTRACT_CONFIG ],
            [SidebarItemIds.USERS]: [ Routes.USERS ],
        };
    }

    public isActive(sidebarItemId: SidebarItemIds, pathname: string): boolean {
        const sidebarRoutes: string[] = this.routes[sidebarItemId];
        return sidebarRoutes.some(route => {
            const match = matchPath(pathname, route);
            return match !== null && match.url === pathname;
        });
    };

}

export const ActiveSidebarItemsInstance = ActiveSidebarItems.getInstance();
