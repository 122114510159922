import i18next from 'i18next';
import translationsEs from './translations/translations.es.json';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'es',
        // Using simple hardcoded resources for simple example
        resources: {
            es: {
                translation: translationsEs,
            },
        },
    });

export default i18next;
