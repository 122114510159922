import * as React from 'react';
import { PsrDashboard } from '../../../../components/psrDashboard/PsrDashboard';
import { CompanyListDataContainer } from "./CompanyListDataContainer";
import { BreadcrumbManager } from '../../../../components/psrDashboard/psrBreadcrumb/BreadcrumbManager';
import { I18nUtils } from '../../../../i18n/I18nUtils';
import { TR_EMPRESAS } from '../../../../i18n/constants';
import { Routes } from '../../../../routing/Routes';

export function CompanyListScreen() {

    const breadcrumbManager = new BreadcrumbManager();
    breadcrumbManager.add({ name: I18nUtils.tr(TR_EMPRESAS), url: Routes.COMPANY_LIST });

    return (
        <PsrDashboard breadcrumbManager={breadcrumbManager}>
            <CompanyListDataContainer/>
        </PsrDashboard>
    );
}
