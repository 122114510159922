import * as React from 'react';
import classNames from 'classnames';
import { PsrFaIcon } from '../../psrIcon/PsrFaIcon';
import { PsrIcon } from '../../psrIcon/PsrIcon';
import { Link } from 'react-router-dom';
import { ActiveSidebarItemsInstance, SidebarItemIds } from './ActiveSidebarItems';

interface Props {
    id: SidebarItemIds
    icon: PsrFaIcon
    label: string
    route: string
    pathname: string
    count?: number
    visible?: boolean
    children?: never
    borderBottom?: boolean
    collapsed: boolean
}

export const PsrSidebarItem: React.FunctionComponent<Props> = (
    {
        id,
        icon,
        label,
        route,
        pathname,
        count = -1,
        visible = true,
        borderBottom,
        collapsed,
    }
) => {
    if ( !visible ) return null;

    const active = ActiveSidebarItemsInstance.isActive(id, pathname);
    const displayCounter: boolean = count > 0;
    const liClasses = classNames({ 'active': active }, 'sidebar-container');
    const divClasses = classNames({ 'collapsed-main-option': collapsed });
    const spanClasses = classNames('p-15', { 'd-none': collapsed });
    const linkClasses = classNames(
        { 'active': active },
        'list-group-item',
        'list-group-item-action',
        'flex-column',
        'align-items-start',
        { 'separator': borderBottom }
    );

    if ( collapsed ) {
        return (
            <li className={ liClasses }>
                <div className={ divClasses }>
                    <Link
                        to={ route }
                        className={ linkClasses }
                    >
                        <PsrIcon icon={ icon }/>
                        <span className={ spanClasses }>{ label }</span>
                        {
                            displayCounter ? (
                                <span className="m-l-5 badge badge-accent">{ count }</span>
                            ) : null
                        }
                    </Link>
                </div>
            </li>
        );
    }

    return (
        <li className={ liClasses }>
            <Link
                to={ route }
                className={ linkClasses }
            >
                <PsrIcon icon={ icon }/>
                <span className={ spanClasses }>{ label }</span>
                {
                    displayCounter ? (
                        <span className="m-l-5 badge badge-accent">{ count }</span>
                    ) : null
                }
            </Link>
        </li>
    );
};
