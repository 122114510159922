import * as  React from 'react';
import { RouterProps, Switch } from 'react-router';
import {Route, Router} from 'react-router-dom';
import {Routes} from './Routes';
import {PsrAlert} from '../components/psrAlert/PsrAlert';
import {LoginScreen} from '../modules/auth/application/LoginScreen';
import {CompanyListScreen} from '../modules/company/list/application/CompanyListScreen';
import AuthRoute from './AuthRoute';
import HomeScreen from "../modules/home/HomeScreen";
import NoAuthRoute from './NoAuthRoute';
import { CompanyContractConfigScreen } from '../modules/company/contract/application/CompanyContractConfigScreen';
import { UsersScreen } from '../modules/user/infrastructure/UsersScreen';

export const Routing: React.FunctionComponent<RouterProps> = (
    {
        history
    }
) => {
    return (
        <Router history={ history }>
            <Switch>
                <NoAuthRoute exact path={ Routes.AUTH_LOGIN } component={ LoginScreen }/>
                <AuthRoute exact path={ Routes.HOME } component={ HomeScreen }/>
                <AuthRoute exact path={ Routes.COMPANY_LIST } component={ CompanyListScreen }/>
                <AuthRoute exact path={ Routes.COMPANY_CONTRACT_CONFIG } render={({match}) => {
                    return <CompanyContractConfigScreen paramUrl={match.params}/>
                }}/>
                <AuthRoute exact path={ Routes.USERS } component={ UsersScreen }/>
                <Route path={ Routes.HOME } component={ PsrAlert }/>
            </Switch>
        </Router>
    );
};
