import { ApiEndpoints } from '../../../shared/infrastructure/ws/ApiEndpoints';
import { METHOD } from '@cuatroochenta/co-generic-request';
import { ApiRequest, ApiResponse } from '../../../shared/infrastructure/ws/ApiRequest';

type Response = ApiResponse<string>;

export class TaskUserExportAcceptComercialCommunications extends ApiRequest<void, Response> {
    public constructor() {
        super(
            METHOD.GET,
            ApiEndpoints.USER_EXPORT_ACCEPT_COMERCIAL_COMMUNICATIONS
        );
    }
}
