import {I18nUtils} from "../../i18n/I18nUtils";
import {TR_BUSCAR} from "../../i18n/constants";
import * as React from "react";

export default function PsrSearcher(props: {
    value: string,
    onChange: (value: string) => void,
}) {
    const {value, onChange} = props;
    return (
        <>
            <div className={"m-b-10"}>
                <input type="text"
                       className="form-control input-filter"
                       placeholder={I18nUtils.tr(TR_BUSCAR)}
                       value={value}
                       onChange={(event) => onChange(event.target.value)}/>
            </div>
        </>
    );
}
