import * as React from 'react';
import logo from '../dashboard-logo.png';

interface Props {
    onClickBrand: () => void
}

export const PsrTopbarBrand: React.FunctionComponent<Props> = (
    {
        onClickBrand,
    }
) => {
    return (
        <div
            className="navbar-brand pointer"
            onClick={ onClickBrand }
        >
            <img
                src={ logo }
                alt={ 'logo' }
                className="rounded-circle"
            />
        </div>
    );
};
