import * as React from 'react';
import useForm, { FormErrors } from '@pavabits/use-form/dist/use-form';
import { PsrCardBody } from '../../../components/psrCard/PsrCardBody';
import { PsrCard } from '../../../components/psrCard/PsrCard';
import { I18nUtils } from '../../../i18n/I18nUtils';
import {
    TR_ACCEDER,
    TR_CONTRASENA,
    TR_INICIO_DE_SESION,
    TR_INTRODUCE_EMAIL,
    TR_INTRODUCE_USUARIO_Y_CONTRASENA_PARA_ACCEDER_A_LA_PLATAFORMA_DE_SOPORTE,
    TR_RECUERDAME,
    TR_SOPORTE_PARA_EL_SISTEMA_DE_GESTION_DE_FACTURAS,
    TR_USUARIO
} from '../../../i18n/constants';
import { PsrRow } from '../../../components/psrLayaout/PsrRow';
import { PsrCheckbox } from '../../../components/psrCheckbox/PsrCheckbox';
import { PsrButton } from '../../../components/psrButton/PsrButton';
import { PsrTextInput } from '../../../components/psrTextInput/PsrTextInput';
import logo from '../auth-logo.png';
import './LoginTemplate.scss';
import { useMemo } from 'react';

interface Props {
    loading: boolean
    error?: string
    onLogin: (email: string, password: string, rememberMe: boolean) => void
}

type LoginData = {
    email: string,
    password: string,
    rememberMe: boolean,
}

const initialValues: LoginData = {
    email: '',
    password: '',
    rememberMe: false,
};

export const LoginTemplate: React.FunctionComponent<Props> = (
    {
        loading,
        error,
        onLogin,
    }
) => {

    const handleOnSubmit = (valid: boolean, values: LoginData, errors: FormErrors<LoginData>) => {
        if (valid) {
            onLogin(values.email, values.password, values.rememberMe);
        }
    };

    const validate = (values: LoginData): FormErrors<LoginData> => {
        const errors: FormErrors<LoginData> = {};
        if (!values.email) {
            errors.email = 'Email obligatorio'
        }
        if (!values.password) {
            errors.password = 'Contraseña obligatoria'
        }
        return errors;
    };


    const { valid, onField, onSubmitForm } = useForm<LoginData>({ initialValues, handleOnSubmit, validate });

    return (
        <PsrCard loading={ loading } className="login-template">
            <PsrCardBody>
                <img
                    src={ logo }
                    className={ 'image-logo' }
                    alt={ I18nUtils.tr(TR_SOPORTE_PARA_EL_SISTEMA_DE_GESTION_DE_FACTURAS) }
                />
                <h1
                    className="signin-title"
                    children={ I18nUtils.tr(TR_INICIO_DE_SESION) }
                />
                <h2
                    className="signin-subtitle"
                    children={ I18nUtils.tr(TR_INTRODUCE_USUARIO_Y_CONTRASENA_PARA_ACCEDER_A_LA_PLATAFORMA_DE_SOPORTE) }
                />
                <form className="form-signin" onSubmit={onSubmitForm}>
                    <PsrTextInput
                        value={ onField('email').value }
                        label={ I18nUtils.tr(TR_USUARIO) }
                        labelFor={ 'email' }
                        type={ 'email' }
                        required={ true }
                        autoFocus={ true }
                        placeholder={ I18nUtils.tr(TR_INTRODUCE_EMAIL) }
                        autoComplete={ 'username' }
                        onChange={ onField('email').onChangeValue }
                        style={ { marginBottom: 20 } }
                    />
                    <PsrTextInput
                        value={ onField('password').value }
                        label={ I18nUtils.tr(TR_CONTRASENA) }
                        labelFor={ 'password' }
                        type={ 'password' }
                        required={ true }
                        placeholder={ I18nUtils.tr(TR_CONTRASENA) }
                        autoComplete={ 'current-password' }
                        onChange={ onField('password').onChangeValue }
                    />
                    <div>
                        <p className={ 'error-message' }>{ error }</p>
                    </div>
                    <div className="checkbox-group">
                        <PsrCheckbox
                            label={ I18nUtils.tr(TR_RECUERDAME) }
                            checked={ onField('rememberMe').value }
                            onChange={ onField('rememberMe').onChangeValue }
                        />
                    </div>
                    <PsrRow className={ 'form-footer-container' }>
                        <PsrButton
                            className={ 'btn btn-lg btn-form btn-submit btn-center' }
                            text={ I18nUtils.tr(TR_ACCEDER) }
                            type={'submit'}
                            disabled={ !valid }
                        />
                    </PsrRow>
                </form>
            </PsrCardBody>
        </PsrCard>
    );
};
