import { sha256 } from 'js-sha256';
import { ApiRequest, ApiResponse } from '../../../shared/infrastructure/ws/ApiRequest';
import { ApiEndpoints } from '../../../shared/infrastructure/ws/ApiEndpoints';
import { I18nUtils } from '../../../../i18n/I18nUtils';
import {
    TR_CONTRASENA_INCORRECTA,
    TR_EL_USUARIO_NO_EXISTE,
    TR_LA_CUENTA_NO_HA_SIDO_VALIDADA
} from '../../../../i18n/constants';
import { METHOD } from '@cuatroochenta/co-generic-request';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - REQUESTS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface SupportAuthLoginRequest {
    email: string
    password: string
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TYPES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

type Request = SupportAuthLoginRequest;
type Response = ApiResponse<string>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - TASKS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class TaskSupportLogin extends ApiRequest<Request, Response> {

    private readonly request: Request;

    public constructor(body: Request) {
        super(
            METHOD.POST,
            ApiEndpoints.SUPPORT_LOGIN,
        );
        this.setErrorMessage(489, I18nUtils.tr(TR_LA_CUENTA_NO_HA_SIDO_VALIDADA));
        this.setErrorMessage(490, I18nUtils.tr(TR_CONTRASENA_INCORRECTA));
        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_NO_EXISTE));
        this.request = body;
    }

    protected getRequest(): Request {
        return {
            email: this.request.email,
            password: sha256(this.request.password)
        };
    }

}
