import { useState } from "react";
import { ContractEntity, PutCompanyContractEntity } from "../../../../shared/infrastructure/ws/ApiModel";
import { PsrAlert } from "../../../../../components/psrAlert/PsrAlert";
import { TaskSupportFinalizeCompanyContract } from "../../infrastructure/ws/TaskSupportFinalizeCompanyContract";
import { TaskSupportGetCompanyContract } from "../../infrastructure/ws/TaskSupportGetCompanyContract";
import { TaskSupportSetContract } from "../../infrastructure/ws/TaskSupportSetContract";
import { TaskSupportUpdateContract } from "../../infrastructure/ws/TaskSupportUpdateContract";

// TODO mover las llamadas de los WS a repositorios
export default function useCompanyContract() {
    const [companyContract, setCompanyContract] = useState<ContractEntity | undefined>(undefined);
    const [companyContractLoading, setCompanyContractLoading] = useState<boolean>(false);


    const createCompanyContract = (companyId: string, companyContract: PutCompanyContractEntity) => {
        new TaskSupportSetContract(companyId, {
            start_date: companyContract.start_date,
            end_date: companyContract.end_date,
            renewal_date: companyContract.renewal_date,
            annual_invoices_limit: companyContract.annual_invoices_limit,
            face_enabled: companyContract.face_enabled,
            faceb2b_enabled: companyContract.faceb2b_enabled,
            file: companyContract.file,
            ocr_enabled: companyContract.ocr_enabled,
            ocr_limit: companyContract.ocr_limit
        })
            .onPreExecution(() => setCompanyContractLoading(true))
            .onPostExecution(() => setCompanyContractLoading(false))
            .onSuccess((result) => {
                setCompanyContract(undefined);
                PsrAlert.success('CONTRATO CREADO CORRECTAMENTE')
            })
            .onFail((error) => {
                PsrAlert.error(error)
            })
            .execute()
    }

    const updateCompanyContract = (companyId: string, companyContract: PutCompanyContractEntity) => {
        new TaskSupportUpdateContract(companyId, {
            start_date: companyContract.start_date,
            end_date: companyContract.end_date,
            renewal_date: companyContract.renewal_date,
            annual_invoices_limit: companyContract.annual_invoices_limit,
            face_enabled: companyContract.face_enabled,
            faceb2b_enabled: companyContract.faceb2b_enabled,
            file: companyContract.file,
            ocr_enabled: companyContract.ocr_enabled,
            ocr_limit: companyContract.ocr_limit
        })
            .onPreExecution(() => setCompanyContractLoading(true))
            .onPostExecution(() => setCompanyContractLoading(false))
            .onSuccess((result) => {
                PsrAlert.success('CONTRATO ACTUALIZADO CORRECTAMENTE')
            })
            .onFail((error) => {
                PsrAlert.error(error)

            }).execute()
    }

    const getCompanyContract = (companyId: string) => {
        new TaskSupportGetCompanyContract(companyId)
            .onPreExecution(() => setCompanyContractLoading(true))
            .onPostExecution(() => setCompanyContractLoading(false))
            .onSuccess((result) => {
                setCompanyContract(result.data);
            })
            .onFail((error) => PsrAlert.error(error))
            .execute()
    }


    const finalizeCompanyContract = (companyId: string) => {
        new TaskSupportFinalizeCompanyContract(companyId)
            .onPreExecution(() => setCompanyContractLoading(true))
            .onPostExecution(() => setCompanyContractLoading(false))
            .onSuccess((result) => {
                setCompanyContract(undefined);
            })
            .onFail((error) => PsrAlert.error(error))
            .execute()
    }
    return ({
        companyContract,
        companyContractLoading,
        finalizeCompanyContract,
        createCompanyContract,
        updateCompanyContract,
        getCompanyContract,
    })
}
