import { PsrFaIcon } from '../../psrIcon/PsrFaIcon';

export interface BreadcrumbItem {
    id?: number
    name: string
    url?: string
    icon?: PsrFaIcon
}

export class BreadcrumbManager {

    private readonly breadcrumbs: BreadcrumbItem[];

    constructor() {
        this.breadcrumbs = [];
    }

    public add(breadcrumbItem: BreadcrumbItem) {
        const newBreadcrumbItem: BreadcrumbItem = { ...breadcrumbItem };
        const length = this.length();
        const empty = this.isEmpty();
        const notSameAsLast = !this.sameAsLast(newBreadcrumbItem);
        if ( empty || notSameAsLast ) {
            newBreadcrumbItem.id = length + 1;
            this.breadcrumbs.push(newBreadcrumbItem);
        }
    }

    private isEquals(b1: BreadcrumbItem, b2: BreadcrumbItem): boolean {
        return b1.name === b2.name && b1.url === b2.url;
    }

    private sameAsLast(breadcrumbItem: BreadcrumbItem): boolean {
        const length = this.length();
        const lastBreadcrumb = this.breadcrumbs[length - 1];
        if ( !lastBreadcrumb ) return false;
        return this.isEquals(lastBreadcrumb, breadcrumbItem);
    }

    public isEmpty(): boolean {
        return this.length() === 0;
    }

    public length(): number {
        return this.breadcrumbs.length;
    }

    public getAll(): BreadcrumbItem[] {
        return this.breadcrumbs;
    }

    public updateLast(newBreadcrumbItem: BreadcrumbItem): void {
        this.breadcrumbs.pop();
        this.breadcrumbs.push(newBreadcrumbItem);
    }

    public update(index: number, newProps: Partial<BreadcrumbItem>): void {
        this.breadcrumbs[index] = {
            ...this.breadcrumbs[index],
            ...newProps,
        };
    }

}
