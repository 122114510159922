import React from "react";
import {AuthManagerInstance} from "../modules/auth/application/auth-manager";
import {Routes} from "./Routes";
import {Redirect, RouteProps} from "react-router";
import {Route} from "react-router-dom";


export default function NoAuthRoute(props: RouteProps) {
    if ( !AuthManagerInstance.isLogged() ) {
        return <Route {...props} />
    } else {
        return <Redirect to={Routes.HOME} />;
    }
}
