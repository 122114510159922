import * as React from 'react';
import { BreadcrumbManager } from "../../../../components/psrDashboard/psrBreadcrumb/BreadcrumbManager";
import { PsrDashboard } from "../../../../components/psrDashboard/PsrDashboard";
import { I18nUtils } from "../../../../i18n/I18nUtils";
import { Routes } from "../../../../routing/Routes";
import { CompanyContractConfigDataContainer } from './CompanyContractConfigDataContainer';
import { TR_CONTRATO } from '../../../../i18n/constants';
import { useParams } from 'react-router';

interface Props {
    paramUrl: string
}

export const CompanyContractConfigScreen: React.FunctionComponent<Props> = ({}) => {
    const { id } = useParams<{id: string}>();

    const breadcrumbManager = new BreadcrumbManager();
    breadcrumbManager.add({ name: I18nUtils.tr(TR_CONTRATO), url: Routes.COMPANY_CONTRACT_CONFIG });
    return (
        <PsrDashboard breadcrumbManager={breadcrumbManager}>
            <CompanyContractConfigDataContainer companyId={id}/>
        </PsrDashboard>
    );
}
