import * as React from 'react';
import { I18nUtils } from '../../../i18n/I18nUtils';
import { TR_CERRAR_SESION } from '../../../i18n/constants';
import { Dropdown } from 'react-bootstrap';
import { PsrDropdownToggle } from '../../psrDropdown/PsrDropdownToggle';

interface Props {
    userName: string
    userImage?: string
    children?: never
    onLogout: () => void
}

export const PsrTopbarUser: React.FunctionComponent<Props> = (
    {
        userName,
        userImage,
        onLogout,
    }
) => {
    return (
        <Dropdown className="dropdown-options">
            <Dropdown.Toggle
                as={ PsrDropdownToggle }
                id="dropdown-custom-components"
            >
                <div className="user-menu">
                    <span className="m-r-10">{ userName }</span>
                    {
                        userImage ? (
                            <img
                                src={ userImage }
                                alt={ 'img-user' }
                                className="img-fluid rounded-circle d-inline-block align-top"
                            />
                        ) : (
                            <div className="initial-letter">
                                { userName && userName.charAt(0) }
                            </div>
                        )
                    }
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={ onLogout }
                    children={ I18nUtils.tr(TR_CERRAR_SESION) }
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};
