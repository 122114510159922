import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { PsrIcon } from '../psrIcon/PsrIcon';
import { PsrFaIcon } from '../psrIcon/PsrFaIcon';
import useServiceProvider from '@pavabits/service-provider/dist/use.service-provider';
import FileRepository from './domain/FileRepository';
import { ServiceKey } from '../../modules/shared/application/service-provider/ServiceKey';
import Uuid from '../../modules/shared/domain/Uuid';
import { FileError, FileExtension, FileValue, FormDragFile } from '@pavabits/form-drag-file/dist';

interface FormFileProps {
    value: FileValue[],
    onChange: (value: FileValue[]) => void,
    companyId: string,
}

export const FormFile: FC<FormFileProps> = ({ companyId, value, onChange }) => {
    const fileRepository = useServiceProvider<FileRepository>(ServiceKey.FileRepository);

    const [uploads, setUploads] = useState<Array<{ id: string, file: File, uploading: boolean }>>([])
    const [error, setError] = useState<string>();

    const uploadRefId = useRef<string>();

    useEffect(() => {
        if (uploads.length > 0 && !uploads[0].uploading) {
            const copyUploads = [...uploads];
            copyUploads[0].uploading = true;
            setUploads(copyUploads)

            uploadRefId.current = uploads[0].id;

            fileRepository.uploadFile(uploads[0].file, companyId)
                .then((uploadFile) => {
                    if (uploads.some((upload) => upload.id === uploadRefId.current)) {
                        onChange([
                            ...value,
                            {
                                id: uploadFile.id,
                                url: uploadFile.url,
                                name: uploadFile.name,
                                loading: false,
                            },
                        ])
                    }
                })
                .catch((e) => setError([e, error].join(' ')))
                .finally(() => {
                    !!uploadRefId.current && setUploads(uploads.filter((upload) => upload.id !== uploadRefId.current))
                })
        }
    }, [uploads, error])

    const onDrop = (files: File[], errors: FileError[]) => {
        setUploads([
            ...uploads,
            ...files.map((file) => ({
                id: Uuid.generate(),
                file,
                uploading: false,
            })),
        ])

        setError(errors.map(fileErrorToString).join(' '))
    }

    const onRemove = (id: string) => {
        if (id === uploadRefId.current) {
            uploadRefId.current = undefined;
        }
        onChange(value.filter((fileValue) => fileValue.id !== id))
        setUploads(uploads.filter((upload) => upload.id !== id))
    }

    const fileErrorToString = (fileError: FileError): string => {
        switch (fileError) {
            case 'file-invalid-type':
                return 'El fichero no es un tipo válido.'
            case 'file-too-large':
                return 'El fichero es demasiado grande.'
            case 'too-many-files':
                return 'Estás intentando subir más ficheros de los permitidos.'
        }
    }

    const valueAndUploads = [
        ...value,
        ...uploads.map((upload) => ({
            id: upload.id,
            url: undefined,
            name: upload.file.name,
            loading: upload.uploading,
        }))
    ]

    return (
        <FormDragFile
            text={'Arrastra aquí el documento del contrato o haga click para seleccionar un fichero'}
            icon={<PsrIcon icon={PsrFaIcon.DOCUMENT}/>}
            accept={[FileExtension.PDF]}
            maxMBSize={20}
            maxFiles={1}
            disabled={false}
            error={error}
            value={valueAndUploads}
            onDrop={onDrop}
            onRemove={onRemove}
        />
    );
}
