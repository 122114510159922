import { CompanyEntity } from '../../../../shared/infrastructure/ws/ApiModel';
import CompanyPlanRepository from '../../domain/company-plan.repository';
import { TaskSupportSetPlantToCompany } from '../../../setPlan/infraestructure/ws/TaskSupportSetPlantToCompany';

export default class ApiCompanyPlanRepository implements CompanyPlanRepository {
    public update(companyId: string, companyPlanId: string): Promise<CompanyEntity> {
        return new TaskSupportSetPlantToCompany(companyId, {
            company_plan_id: companyPlanId,
        })
            .executeAsPromise()
            .then((result) => result.data)
    }
}
