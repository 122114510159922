import { useState } from 'react';
import useServiceProvider from '@pavabits/service-provider/dist/use.service-provider';
import UserRepository from '../domain/UserRepository';
import { ServiceKey } from '../../shared/application/service-provider/ServiceKey';

type Return = {
    loading: boolean,
    exportExcel: () => void,
}

export const useExportUserAcceptComercialCommunications = (): Return => {
    const [loading, setLoading] = useState(false);
    const userRepository = useServiceProvider<UserRepository>(ServiceKey.UserRepository);

    const exportExcel = () => {
        setLoading(true)
        userRepository.exportUsersComercialCommunications()
            .then((url) => window.open(url.value, '_blank'))
            .finally(() => setLoading(false))
    }

    return {
        loading,
        exportExcel,
    }
}
