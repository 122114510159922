import UserRepository from '../domain/UserRepository';
import Url from '../../shared/domain/Url';
import { TaskUserExportAcceptComercialCommunications } from './ws/TaskUserExportAcceptComercialCommunications';

export default class ApiUserRepository implements UserRepository {
    public exportUsersComercialCommunications(): Promise<Url> {
        return new TaskUserExportAcceptComercialCommunications()
            .executeAsPromise()
            .then((res) => new Url(res.data));
    }
}
