import * as React from 'react';
import { MouseEventHandler } from 'react';
import { StyledProps } from '../StyledProps';
import classNames from 'classnames';

interface Props {
    onChange: (checked: boolean) => void
    checked: boolean
    className?: string
    disabled?: boolean
    label?: string
    name?: string
    classNameContainer?: string
    classNameInput?: string
    classNameLabel?: string
    classNameInner?: string
    children?: never
}

export const PsrCheckbox: React.FunctionComponent<Props & StyledProps> = (
    {
        onChange,
        checked,
        disabled,
        label,
        name,
        classNameContainer,
        classNameInput,
        classNameLabel,
        classNameInner,
        className,
        style,
    }
) => {
    const cursorClass = disabled ? 'disable-cursor' : '';
    const classes = classNames('checkbox-group', className);
    const classesContainer = classNames({ 'display-inline-flex m-t-20': label }, classNameContainer);
    const classesInner = `row-checkbox pointer ${ classNameInner } ${ cursorClass }`;
    const classesCursor = `row-input-checkbox ${ cursorClass } ${ classNameInput }`;
    const classesLabel = `checkbox-label font-bold p-l-30 pointer ${ classNameLabel }`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);
    const handleClick: MouseEventHandler<HTMLInputElement | HTMLSpanElement> =
        (event) => event.stopPropagation();

    return (
        <div className={ classes } style={ style }>
            <span className={ classesContainer }>
                    <label className={ classesInner }>
                        <input
                            name={ name }
                            type={ 'checkbox' }
                            checked={ checked }
                            onChange={ handleChange }
                            disabled={ disabled }
                            className={ classNameInput }
                            onClick={ handleClick }
                        />
                        <span
                            className={ classesCursor }
                            onClick={ handleClick }
                        />
                    </label>
                {
                    label && onChange ?
                        <label
                            className={ classesLabel }
                            onClick={ () => onChange(!checked) }
                            children={ label }
                        /> : null
                }
            </span>
        </div>
    );
};
