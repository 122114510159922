import * as React from 'react';
import Switch from "@material/react-switch";

export interface LabelSwitchProps {
    labelClassName?: string,
    label?: string,
    labelDisabled?: string,
    labelEnabled?: string,
    checked: boolean,
    disabled?: boolean,
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void,
}

export class PsrSwitch extends React.Component<LabelSwitchProps> {

    render() {
        const {labelClassName = '', label, labelDisabled, labelEnabled, checked, onChange, disabled = false} = this.props;
        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}
                <div>
                    {labelDisabled && <span className={"pointer"}>{labelDisabled || ''}</span>}
                    <Switch
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                    />
                    {labelEnabled && <span className={"pointer"}>{labelEnabled || ''}</span>}
                </div>
            </>
        );
    }
}
